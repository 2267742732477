export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 31"
    >
      <g>
        <path
          d="M3.68,29.8c1.3,0,1.58-0.28,1.74-0.77h0.17l-0.16,1.23c-0.17-0.05-0.51-0.09-0.96-0.09H0v-0.13
          c0.3-0.38,0.62-0.76,0.91-1.14l3.49-4.61H1.26c-0.49,0-0.83,0.2-1,0.66H0.08l0.04-1.18h0.11c0.27,0.11,0.93,0.14,2.56,0.14
          c1.8,0,2.34-0.05,2.63-0.11h0.24v0.13c-1.72,1.87-3.11,4.04-4.45,5.86H3.68z"
        />
        <path
          d="M11,24.87h-0.16c-0.09-0.41-0.43-0.64-1.19-0.64H8.24c-0.05,0.4-0.07,1.13-0.07,1.67v0.91h1.58c0.51,0,0.68-0.12,0.78-0.46
          h0.16c-0.01,0.19-0.03,0.4-0.03,0.61c0,0.21,0.02,0.42,0.03,0.62h-0.16c-0.1-0.34-0.27-0.45-0.78-0.45H8.17v2.74h1.88
          c0.58,0,0.83-0.26,1-0.62l0.14,0.03l-0.14,0.99c-0.32-0.06-0.62-0.08-1.2-0.08H6.94v-0.13c0.37-0.02,0.51-0.14,0.51-0.54v-2.52
          c0-2.81-0.04-2.88-0.51-2.9v-0.16h3.6c0.2,0,0.25-0.05,0.3-0.14H11V24.87z"
        />
        <path
          d="M16.67,28.97l2.54-5.19h0.15l0.26,5.39c0.04,0.73,0.09,0.81,0.49,0.88v0.16c-0.26,0-0.53-0.04-0.85-0.04
          c-0.26,0-0.53,0.04-0.76,0.04v-0.16c0.38-0.03,0.45-0.29,0.45-0.62c0-0.34-0.02-0.63-0.03-0.78l-0.18-3.21h-0.04l-2.25,4.57
          l-2.62-4.58H13.8l-0.17,2.84c-0.02,0.24-0.02,0.65-0.02,0.89c0,0.63,0.25,0.85,0.56,0.89v0.16c-0.25,0-0.52-0.04-0.79-0.04
          c-0.26,0-0.52,0.04-0.78,0.04v-0.16c0.48-0.11,0.55-0.26,0.61-1.05l0.34-5.22h0.16L16.67,28.97z"
        />
        <path
          d="M23.54,24.05c-0.35,0-0.45,0.26-0.45,0.54l-0.05,4.91c0,0.28,0.14,0.54,0.42,0.54v0.16c-0.19,0-0.46-0.04-0.77-0.04
          c-0.27,0-0.54,0.04-0.72,0.04v-0.16c0.25,0,0.4-0.26,0.4-0.54l-0.05-4.91c0-0.28-0.1-0.54-0.45-0.54v-0.16
          c0.28,0,0.55,0.04,0.82,0.04c0.31,0,0.58-0.04,0.85-0.04V24.05z"
        />
        <path
          d="M29,24.94c0-0.8-0.11-0.89-0.43-0.89v-0.16c0.31,0,0.6,0.04,0.89,0.04c0.29,0,0.58-0.04,0.87-0.04
          c0.95,0,1.89,0.38,1.89,1.58c0,0.79-0.55,1.29-1.28,1.48v0.02c0.93,0.21,1.41,0.86,1.41,1.57c0,1.45-1.08,1.68-2.03,1.68
          c-0.29,0-0.58-0.04-0.87-0.04c-0.37,0-0.7,0.04-0.9,0.04v-0.16c0.34,0,0.46-0.26,0.46-0.54L29,24.94z M29.74,29.53
          c0,0.34,0.08,0.43,0.43,0.43c0.97,0,1.46-0.55,1.46-1.48c0-0.96-0.61-1.41-1.39-1.41h-0.48L29.74,29.53z M29.75,26.81h0.27
          c0.9,0,1.47-0.31,1.47-1.28c0-0.96-0.56-1.48-1.36-1.48c-0.33,0-0.37,0.14-0.37,0.41L29.75,26.81z"
        />
        <path
          d="M38.08,24.87h-0.16c-0.09-0.41-0.43-0.64-1.19-0.64h-1.41c-0.05,0.4-0.07,1.13-0.07,1.67v0.91h1.58
          c0.51,0,0.68-0.12,0.78-0.46h0.16c-0.01,0.19-0.03,0.4-0.03,0.61c0,0.21,0.02,0.42,0.03,0.62h-0.16c-0.1-0.34-0.27-0.45-0.78-0.45
          h-1.58v2.74h1.88c0.58,0,0.83-0.26,1-0.62l0.14,0.03l-0.14,0.99c-0.32-0.06-0.62-0.08-1.2-0.08h-2.92v-0.13
          c0.37-0.02,0.51-0.14,0.51-0.54v-2.52c0-2.81-0.04-2.88-0.51-2.9v-0.16h3.6c0.2,0,0.25-0.05,0.3-0.14h0.16V24.87z"
        />
        <path
          d="M40.98,28.4l-0.29,0.8c-0.06,0.17-0.06,0.4-0.06,0.49c0,0.16,0.16,0.28,0.39,0.36v0.16c-0.23,0-0.46-0.04-0.69-0.04
          c-0.24,0-0.47,0.04-0.75,0.04v-0.16c0.45-0.14,0.52-0.36,0.68-0.76l2.19-5.51h0.15l1.96,5.51c0.15,0.43,0.28,0.6,0.72,0.76v0.16
          c-0.27,0-0.54-0.04-0.82-0.04c-0.27,0-0.54,0.04-0.81,0.04v-0.16c0.12-0.08,0.32-0.2,0.32-0.31c0-0.14-0.05-0.26-0.1-0.39
          l-0.35-0.95H40.98z M41.13,28.03h2.27l-1.07-2.95L41.13,28.03z"
        />
        <path
          d="M52.28,30.06c-0.57,0.16-1.34,0.25-1.93,0.25c-2.16,0-3.6-1.32-3.6-3.27s1.44-3.27,3.6-3.27c0.81,0,1.67,0.33,1.86,0.33
          c0.04,0,0.08-0.04,0.13-0.05l0.1,0.97h-0.16c-0.32-0.7-1.24-0.96-1.92-0.96c-1.62,0-2.74,1.07-2.74,2.98
          c0,1.37,1.23,2.98,2.74,2.98c1.08,0,1.69-0.27,2.13-0.94l0.12,0.06L52.28,30.06z"
        />
        <path
          d="M55.32,27.09v2.41c0,0.28,0.12,0.54,0.33,0.54v0.16c-0.14,0-0.41-0.04-0.72-0.04c-0.27,0-0.54,0.04-0.68,0.04v-0.16
          c0.22,0,0.35-0.26,0.35-0.54v-3.31c-0.01-2.06-0.06-2.17-0.44-2.14v-0.16c0.23,0,0.5,0.04,0.77,0.04c0.31,0,0.58-0.04,0.81-0.04
          v0.16c-0.31,0-0.41,0.26-0.41,0.54l-0.02,2.13h4.16l-0.02-2.13c0-0.28-0.1-0.54-0.41-0.54v-0.16c0.23,0,0.5,0.04,0.81,0.04
          c0.27,0,0.54-0.04,0.77-0.04v0.16c-0.38-0.04-0.43,0.07-0.44,2.14v3.31c0,0.28,0.14,0.54,0.35,0.54v0.16
          c-0.14,0-0.41-0.04-0.68-0.04c-0.31,0-0.58,0.04-0.72,0.04v-0.16c0.22,0,0.33-0.26,0.33-0.54v-2.41H55.32z"
        />
        <path
          d="M66.78,27.09v2.41c0,0.28,0.12,0.54,0.33,0.54v0.16c-0.14,0-0.41-0.04-0.72-0.04c-0.27,0-0.54,0.04-0.68,0.04v-0.16
          c0.22,0,0.35-0.26,0.35-0.54v-3.31c-0.01-2.06-0.06-2.17-0.44-2.14v-0.16c0.23,0,0.5,0.04,0.77,0.04c0.31,0,0.58-0.04,0.81-0.04
          v0.16c-0.31,0-0.41,0.26-0.41,0.54l-0.02,2.13h4.16l-0.02-2.13c0-0.28-0.1-0.54-0.41-0.54v-0.16c0.23,0,0.5,0.04,0.81,0.04
          c0.27,0,0.54-0.04,0.77-0.04v0.16c-0.38-0.04-0.43,0.07-0.44,2.14v3.31c0,0.28,0.14,0.54,0.35,0.54v0.16
          c-0.14,0-0.41-0.04-0.68-0.04c-0.31,0-0.58,0.04-0.72,0.04v-0.16c0.22,0,0.33-0.26,0.33-0.54v-2.41H66.78z"
        />
        <path
          d="M77.22,23.78c1.88,0,3.27,1.41,3.27,3.27c0,1.86-1.39,3.27-3.27,3.27c-1.88,0-3.27-1.41-3.27-3.27
          C73.95,25.19,75.33,23.78,77.22,23.78z M77.22,30.03c1.69,0,2.41-1.32,2.41-2.98c0-1.66-0.72-2.98-2.41-2.98
          c-1.69,0-2.41,1.32-2.41,2.98C74.81,28.7,75.52,30.03,77.22,30.03z"
        />
        <path
          d="M87.24,24.59c0-0.28-0.09-0.54-0.45-0.54v-0.16c0.28,0,0.55,0.04,0.82,0.04c0.31,0,0.58-0.04,0.81-0.04v0.16
          c-0.31,0-0.4,0.26-0.4,0.54l-0.03,4.91c0,0.38,0.22,0.54,0.53,0.54v0.16c-0.23,0-0.45-0.04-0.68-0.04c-0.23,0-0.45,0.04-0.68,0.04
          v-0.06c0.05-0.05,0.09-0.14,0.09-0.24v-0.44c-0.62,0.43-1.31,0.86-2.16,0.86c-1.34,0-2.26-0.85-2.26-2.32l-0.03-3.4
          c0-0.28-0.13-0.54-0.47-0.54v-0.16c0.29,0,0.56,0.04,0.84,0.04c0.31,0,0.58-0.04,0.84-0.04v0.16c-0.34,0-0.43,0.26-0.43,0.54
          l-0.03,3.37c0,1.43,1,1.88,1.72,1.88c0.71,0,1.65-0.29,2-0.86L87.24,24.59z"
        />
        <path
          d="M93.77,24.92c-0.19-0.59-0.84-0.85-1.41-0.85c-0.73,0-1.44,0.38-1.44,1.2c0,1.95,3.2,1.09,3.2,3.25
          c0,1.18-0.85,1.79-1.96,1.79c-0.55,0-1.11-0.1-1.68-0.26l-0.04-0.94h0.16c0.21,0.64,0.93,0.91,1.54,0.91
          c0.72,0,1.34-0.35,1.34-1.15c0-1.92-3.2-0.91-3.2-3.25c0-1.31,1.03-1.85,2.22-1.85c0.47,0,0.92,0.14,1.39,0.13l0.04,1.01H93.77z"
        />
        <path
          d="M99.81,24.87h-0.16c-0.09-0.41-0.43-0.64-1.19-0.64h-1.41c-0.05,0.4-0.07,1.13-0.07,1.67v0.91h1.58
          c0.51,0,0.68-0.12,0.78-0.46h0.16c-0.01,0.19-0.03,0.4-0.03,0.61c0,0.21,0.02,0.42,0.03,0.62h-0.16c-0.1-0.34-0.27-0.45-0.78-0.45
          h-1.58v2.74h1.88c0.58,0,0.83-0.26,1-0.62l0.14,0.03l-0.14,0.99c-0.32-0.06-0.62-0.08-1.2-0.08h-2.92v-0.13
          c0.37-0.02,0.51-0.14,0.51-0.54v-2.52c0-2.81-0.04-2.88-0.51-2.9v-0.16h3.6c0.2,0,0.25-0.05,0.3-0.14h0.16V24.87z"
        />
      </g>
      <g>
        <path
          d="M48.08,13.8c0-0.02-0.01-0.03-0.01-0.05c-0.01-0.02-0.02-0.05-0.03-0.07c-0.02-0.05-0.03-0.11-0.06-0.16
          c-0.01-0.01-0.01-0.03-0.01-0.04c-0.02-0.06-0.04-0.12-0.07-0.18c-0.01-0.01-0.01-0.03-0.02-0.04c-0.01-0.06-0.02-0.12-0.05-0.17
          c-0.01-0.01-0.01-0.02-0.01-0.03c0-0.05-0.02-0.08-0.04-0.12c-0.03-0.04-0.06-0.08-0.08-0.12c-0.03-0.06-0.06-0.12-0.08-0.19
          c-0.01-0.05-0.03-0.09-0.05-0.14c-0.03-0.08-0.08-0.14-0.1-0.22c0-0.01-0.01-0.02-0.02-0.04c-0.01-0.02-0.02-0.03-0.03-0.05
          c-0.03-0.05-0.06-0.09-0.07-0.15c-0.06-0.1-0.12-0.21-0.2-0.3c-0.02-0.02-0.05-0.05-0.06-0.08c0-0.01-0.02-0.02-0.03-0.04
          c-0.02-0.02-0.04-0.04-0.05-0.06c-0.02-0.04-0.04-0.08-0.07-0.12c-0.05-0.09-0.11-0.17-0.15-0.26c-0.02-0.04-0.06-0.08-0.08-0.11
          c-0.03-0.05-0.07-0.1-0.1-0.14c-0.02-0.02-0.04-0.05-0.05-0.07c-0.01-0.01-0.02-0.03-0.03-0.04c-0.06-0.05-0.1-0.12-0.14-0.19
          c-0.02-0.04-0.05-0.08-0.09-0.1c-0.02-0.01-0.04-0.03-0.06-0.05c-0.05-0.05-0.12-0.08-0.19-0.1c-0.04-0.01-0.08-0.02-0.12-0.02
          c-0.06,0.01-0.13,0.02-0.17,0.09c-0.01,0.02-0.02,0.03-0.04,0.04c-0.01,0.02-0.03,0.03-0.03,0.05c-0.01,0.03-0.02,0.06-0.04,0.08
          c-0.01,0.01-0.01,0.02-0.01,0.03c0,0.02,0,0.03,0,0.05c-0.02,0.06,0,0.12,0,0.18c0.01,0.05,0.01,0.1,0,0.15
          c-0.01,0.04,0,0.07,0.02,0.1c0.02,0.02,0.02,0.05,0.03,0.08c0.01,0.05,0.03,0.1,0.06,0.15c0.05,0.06,0.08,0.14,0.09,0.22
          c0,0.01,0,0.01,0,0.02c0.03,0.07,0.04,0.14,0.07,0.21c0.01,0.04,0.03,0.07,0.04,0.11c0.02,0.06,0.03,0.13,0.07,0.18
          c0.01,0.01,0.01,0.02,0.01,0.03c0.03,0.07,0.06,0.15,0.09,0.22c0.01,0.03,0.03,0.06,0.05,0.09c0.05,0.11,0.1,0.21,0.13,0.32
          c0,0.01,0.01,0.02,0.01,0.03c0.03,0.03,0.03,0.07,0.05,0.1c0.01,0.03,0.02,0.06,0.04,0.08c0.01,0.01,0.01,0.02,0.02,0.04
          c0.01,0.03,0.02,0.06,0.04,0.08c0.02,0.02,0.04,0.05,0.05,0.08c0,0.01,0.01,0.03,0.02,0.04c0.03,0.03,0.04,0.07,0.06,0.11
          c0.02,0.04,0.04,0.07,0.06,0.1c0,0,0,0.01,0.01,0.01c0.01,0.03,0.03,0.06,0.04,0.08c0.02,0.04,0.05,0.08,0.08,0.12
          c0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.04,0.03,0.08,0.06,0.11c0.05,0.06,0.09,0.12,0.14,0.18c0.02,0.02,0.03,0.04,0.05,0.06
          c0.04,0.04,0.08,0.09,0.14,0.09c0.07,0.04,0.14,0.05,0.22,0.05c0.07,0.01,0.14,0.01,0.21,0.02c0.05,0,0.09,0,0.13-0.01
          c0.05-0.01,0.1-0.02,0.15-0.02c0.08,0,0.14-0.03,0.2-0.08c0.03-0.02,0.07-0.05,0.06-0.1c0-0.04,0-0.08-0.03-0.12
          C48.09,13.86,48.08,13.83,48.08,13.8z M46.23,13.86c-0.01-0.02-0.03-0.04-0.04-0.06c-0.01-0.03-0.03-0.05-0.04-0.07
          c-0.02-0.02-0.04-0.04-0.05-0.07c-0.01-0.05-0.05-0.1-0.08-0.14c-0.01-0.02-0.02-0.04-0.03-0.05c-0.01-0.03-0.02-0.06-0.04-0.09
          c-0.07-0.08-0.1-0.18-0.16-0.27c-0.01-0.01-0.01-0.03-0.01-0.04c-0.01-0.04-0.02-0.07-0.02-0.11c0-0.02-0.01-0.04-0.02-0.05
          c-0.03-0.04-0.05-0.08-0.07-0.12c-0.02-0.05-0.05-0.09-0.08-0.13c-0.03-0.05-0.06-0.1-0.07-0.16c-0.01-0.05-0.03-0.09-0.05-0.13
          c-0.02-0.05-0.05-0.11-0.07-0.16c-0.01-0.02-0.01-0.04-0.02-0.05c-0.01-0.05-0.02-0.1-0.05-0.15c-0.02-0.03-0.03-0.07-0.03-0.11
          c0-0.04-0.01-0.08-0.02-0.12c-0.04-0.1-0.05-0.22-0.09-0.32c-0.01-0.01-0.01-0.03-0.01-0.05c0-0.02,0-0.03,0-0.05
          c-0.01-0.07-0.02-0.14-0.02-0.21c-0.01-0.07,0.01-0.14,0-0.21c0-0.03,0.01-0.07,0-0.1c0-0.04,0-0.07,0.02-0.1
          c0.01-0.03,0.01-0.05,0.01-0.08c0-0.03-0.01-0.07,0.02-0.1c0,0,0,0,0-0.01c0.01-0.07,0.02-0.13,0.03-0.2c0-0.01,0-0.02-0.01-0.03
          c-0.01-0.02-0.03-0.05-0.04-0.07c-0.01-0.01-0.02-0.02-0.02-0.03c-0.02-0.03-0.04-0.07-0.06-0.1c-0.03-0.05-0.07-0.1-0.12-0.13
          c-0.08-0.05-0.15-0.11-0.24-0.15c-0.09-0.04-0.17-0.11-0.26-0.15c-0.01,0-0.02-0.01-0.03-0.02c-0.03-0.03-0.06-0.06-0.1-0.08
          c-0.07-0.04-0.12-0.1-0.18-0.15c-0.06-0.06-0.11-0.12-0.17-0.18c-0.03-0.03-0.05-0.07-0.08-0.09c-0.04-0.03-0.07-0.07-0.1-0.11
          C43.8,9,43.76,8.95,43.72,8.91c-0.02-0.03-0.05-0.05-0.08-0.06c-0.04-0.02-0.08-0.03-0.12-0.05c-0.03-0.01-0.06-0.01-0.09,0.01
          c-0.03,0.02-0.06,0.05-0.08,0.08c-0.01,0.01-0.02,0.02-0.02,0.04c0,0.06-0.01,0.11-0.01,0.17c0,0.08,0.01,0.16,0.01,0.25
          c0,0.05,0.01,0.1-0.01,0.15c0,0,0,0.01,0,0.01c0,0.03,0,0.07,0.01,0.1c0.01,0.04-0.01,0.08,0,0.11c0.01,0.05,0.01,0.1,0.02,0.15
          c0.01,0.06,0,0.13,0.03,0.19c0,0.01,0,0.02,0,0.03c0.01,0.07,0.01,0.15,0.02,0.22c0.01,0.05,0.01,0.11,0.01,0.16
          c0,0.06,0.01,0.11,0.02,0.17c0.01,0.05,0.03,0.1,0.01,0.16c0,0.01,0,0.02,0.01,0.03c0.02,0.05,0.03,0.11,0.05,0.17
          c0.01,0.02,0.01,0.05,0.01,0.08c0,0.01,0.01,0.03,0.01,0.05c0.01,0.02,0.02,0.03,0.02,0.05c0,0.06,0.01,0.12,0.03,0.17
          c0,0.01,0,0.01,0,0.02c0,0.01,0,0.03,0.01,0.04c0.01,0.03,0.02,0.06,0.02,0.09c0,0.03,0,0.06,0.01,0.08
          c0.01,0.02,0.02,0.05,0.02,0.07c0.01,0.04,0.02,0.08,0.02,0.12c0.01,0.09,0.02,0.19,0.03,0.28c0,0.03,0,0.05,0.02,0.07
          c0.02,0.05,0.04,0.09,0.05,0.15c0.01,0.05,0.03,0.09,0.05,0.14c0.02,0.04,0.05,0.09,0.06,0.13c0.02,0.07,0.05,0.13,0.09,0.18
          c0.02,0.03,0.04,0.06,0.04,0.1c0.01,0.05,0.04,0.09,0.06,0.14c0.02,0.05,0.04,0.09,0.08,0.13c0,0,0.01,0.01,0.01,0.01
          c0.02,0.05,0.06,0.09,0.1,0.12c0.01,0.01,0.02,0.03,0.03,0.04c0.06,0.07,0.11,0.14,0.18,0.21c0.04,0.04,0.08,0.09,0.11,0.15
          c0.01,0.02,0.02,0.03,0.04,0.05c0.05,0.06,0.12,0.1,0.17,0.16c0.01,0.01,0.02,0.02,0.03,0.03c0.03,0.02,0.06,0.04,0.08,0.08
          c0.03,0.05,0.09,0.08,0.12,0.13c0.01,0.01,0.02,0.01,0.03,0.02c0.04,0.02,0.07,0.05,0.11,0.07c0.02,0.01,0.04,0.03,0.06,0.04
          c0.05,0.02,0.09,0.04,0.15,0.05c0.02,0,0.04,0.01,0.06,0.02c0.04,0.02,0.09,0.04,0.13,0.06c0.02,0.01,0.04,0.01,0.06,0.01
          c0.11,0,0.21,0.01,0.32,0.01c0.07,0.01,0.13-0.01,0.19-0.05c0.04-0.03,0.07-0.05,0.1-0.08c0.06-0.05,0.08-0.12,0.09-0.19
          c0.01-0.05-0.02-0.08-0.04-0.13C46.25,13.88,46.24,13.87,46.23,13.86z M44.1,13.9c0-0.02-0.01-0.04-0.03-0.05
          c-0.04-0.04-0.07-0.08-0.1-0.13c-0.03-0.05-0.07-0.1-0.11-0.15c-0.06-0.06-0.11-0.12-0.16-0.19c-0.05-0.05-0.09-0.11-0.1-0.18
          c-0.01-0.03-0.02-0.05-0.02-0.08c-0.01-0.04-0.02-0.09-0.04-0.13c-0.03-0.07-0.06-0.14-0.09-0.21c-0.04-0.12-0.1-0.23-0.14-0.35
          c-0.02-0.04-0.04-0.08-0.04-0.13c0-0.01-0.01-0.02-0.01-0.03c-0.02-0.03-0.04-0.07-0.05-0.1c-0.01-0.03-0.03-0.06-0.03-0.1
          c0-0.02,0-0.04-0.01-0.06c-0.03-0.06-0.04-0.13-0.06-0.19c-0.03-0.08-0.05-0.17-0.07-0.25c-0.01-0.03-0.01-0.06-0.02-0.1
          c-0.02-0.05-0.03-0.11-0.03-0.17c-0.01-0.05-0.01-0.1-0.02-0.15c-0.01-0.05-0.03-0.09-0.02-0.14c0-0.01,0-0.02,0-0.03
          c-0.01-0.03-0.01-0.07-0.02-0.1c-0.01-0.04-0.01-0.08-0.03-0.12c-0.02-0.05-0.03-0.09-0.03-0.15c0-0.07,0.01-0.13-0.01-0.2
          c0-0.01,0-0.01,0-0.02c0-0.08,0-0.16-0.01-0.23c0-0.01,0-0.03,0-0.04c0-0.02,0-0.05,0-0.07c0-0.03,0-0.06,0-0.09
          c0-0.01,0-0.03,0-0.04c-0.01-0.04-0.02-0.07-0.01-0.11c0-0.02,0-0.03,0-0.05c0-0.04-0.01-0.09-0.01-0.13
          c0-0.08-0.01-0.16-0.02-0.25c0-0.04-0.01-0.08-0.01-0.12c0-0.08-0.01-0.17-0.01-0.25c0-0.09,0-0.18-0.03-0.27
          c-0.01-0.05-0.04-0.09-0.06-0.13c-0.04-0.08-0.11-0.11-0.19-0.1c-0.02,0-0.04,0-0.05,0.01c-0.03,0.02-0.07,0.04-0.1,0.07
          c-0.05,0.05-0.11,0.1-0.18,0.12c-0.01,0-0.02,0.01-0.03,0.02c-0.01,0.01-0.02,0.02-0.03,0.03c-0.01,0.03-0.03,0.04-0.05,0.06
          C42.03,8.83,42.02,8.85,42,8.88C41.97,8.92,41.94,8.95,41.91,9c-0.02,0.03-0.04,0.07-0.06,0.1c-0.02,0.04-0.01,0.09-0.02,0.13
          c0.03,0.04,0.02,0.09,0.03,0.14c0.01,0.08,0.01,0.16,0.01,0.25c0,0.01,0,0.02-0.01,0.03c-0.02,0.06-0.01,0.13-0.03,0.19
          c0,0.01,0,0.02,0,0.03c0.01,0.06,0.01,0.12-0.01,0.17c0,0.01-0.01,0.02-0.01,0.03c0,0.01-0.01,0.02,0,0.03
          c0.02,0.05,0,0.1,0.01,0.15c0,0.01,0,0.02,0,0.03c-0.03,0.08-0.03,0.17-0.03,0.26c0,0.05,0,0.1-0.03,0.14
          c-0.01,0.01-0.01,0.03-0.01,0.05c-0.01,0.05-0.01,0.09-0.02,0.14c-0.02,0.08-0.04,0.15-0.06,0.23c-0.01,0.03-0.01,0.06-0.03,0.09
          c-0.02,0.03-0.02,0.06-0.03,0.09c-0.01,0.08-0.03,0.17-0.07,0.24c-0.02,0.04-0.03,0.07-0.03,0.11c0,0.01,0,0.03-0.01,0.04
          c-0.03,0.04-0.04,0.09-0.05,0.13c-0.01,0.02-0.01,0.04-0.03,0.06c-0.02,0.02-0.04,0.04-0.05,0.07c-0.02,0.07-0.06,0.14-0.07,0.22
          c0,0.02-0.01,0.04-0.03,0.06c-0.01,0.02-0.02,0.04-0.03,0.06c-0.01,0.02-0.02,0.04-0.03,0.06c-0.03,0.05-0.06,0.1-0.1,0.15
          c-0.03,0.03-0.06,0.06-0.06,0.11c-0.03,0.04-0.06,0.09-0.08,0.14c0,0.01-0.01,0.02-0.02,0.02c-0.03,0.02-0.04,0.04-0.05,0.07
          c-0.04,0.08-0.11,0.15-0.15,0.23c0,0.01-0.01,0.01-0.01,0.02c-0.03,0.03-0.05,0.06-0.08,0.09c-0.05,0.08-0.13,0.13-0.17,0.22
          c0,0,0,0-0.01,0c-0.07,0.06-0.13,0.14-0.19,0.2c-0.01,0.01-0.01,0.01-0.02,0.02c-0.04,0.07-0.1,0.12-0.13,0.18
          c-0.02,0.03-0.05,0.07-0.07,0.1c-0.02,0.03-0.03,0.06-0.02,0.09c0,0.04,0.01,0.07,0.04,0.1c0.04,0.05,0.09,0.09,0.14,0.13
          c0.02,0.01,0.04,0.02,0.05,0.03c0.05,0.04,0.11,0.06,0.18,0.07c0.12,0.02,0.24,0.04,0.35,0.07c0.03,0.01,0.06,0.01,0.08,0.01
          c0.05,0,0.11,0.01,0.16,0.03c0.06,0.01,0.13,0.02,0.19,0.03c0.01,0,0.02,0,0.03,0c0.08-0.01,0.16,0,0.23,0.02
          c0.04,0.01,0.07,0.01,0.11,0c0.08-0.02,0.16-0.02,0.25-0.01c0.04,0.01,0.07,0,0.1,0c0.04-0.01,0.08-0.02,0.12-0.02
          c0.08,0,0.16,0.01,0.24-0.01c0.02,0,0.04,0,0.06,0c0.04,0.01,0.08,0,0.11,0c0.04,0,0.08-0.01,0.13,0c0.01,0,0.03,0,0.04,0
          c0.07-0.01,0.14-0.02,0.22-0.03c0.07-0.01,0.15,0,0.22-0.02c0.01,0,0.02,0,0.03,0c0.05,0,0.1,0,0.15-0.01
          c0.07-0.01,0.14-0.02,0.21-0.01c0.03,0,0.06,0,0.09,0c0.04-0.01,0.07-0.01,0.11,0c0.04,0,0.09,0.01,0.12-0.01
          c0.04-0.02,0.09-0.03,0.14-0.04c0.06,0,0.12-0.03,0.14-0.09c0.02-0.04,0.04-0.09,0.03-0.14c0-0.02,0-0.05-0.01-0.07
          C44.12,13.99,44.1,13.95,44.1,13.9z M50.53,0.56c-0.01,0.05,0,0.1,0.03,0.15c0,0,0,0.01,0,0.01c0.01,0.07,0.03,0.15,0.04,0.22
          c0,0.03,0,0.05,0.01,0.08c0.01,0.06,0.03,0.12,0.05,0.18c0.01,0.05,0.03,0.1,0.04,0.15c0,0.07,0.02,0.13,0.04,0.19
          c0.02,0.06,0.04,0.12,0.06,0.17c0.02,0.05,0.04,0.09,0.04,0.14c0,0.01,0,0.02,0.01,0.03c0.04,0.08,0.07,0.17,0.11,0.25
          c0,0.01,0.01,0.01,0.01,0.02c0.03,0.04,0.05,0.08,0.08,0.12c0,0.01,0.01,0.02,0.01,0.03c0,0.06,0.04,0.1,0.06,0.15
          c0.02,0.05,0.05,0.1,0.07,0.14c0.03,0.05,0.05,0.09,0.05,0.15c0,0.03,0.01,0.05,0.03,0.08c0.01,0.01,0.03,0.04,0.03,0.05
          c0,0.04,0.03,0.07,0.06,0.09C51.37,2.98,51.38,3,51.4,3.02c0.03,0.04,0.06,0.08,0.09,0.13c0,0.01,0.01,0.02,0.02,0.03
          c0.03,0.03,0.06,0.06,0.07,0.11c0,0.01,0.02,0.03,0.03,0.04c0.04,0.05,0.08,0.09,0.11,0.16c0,0.01,0.02,0.02,0.03,0.03
          c0.02,0.02,0.04,0.04,0.06,0.06c0.02,0.02,0.04,0.03,0.07,0.05c0,0,0.01,0.01,0.01,0.01c0.03,0.02,0.05,0.05,0.08,0.07
          c0.03,0.02,0.05,0.05,0.07,0.07c0.02,0.02,0.04,0.04,0.06,0.06c0.1,0.07,0.18,0.16,0.28,0.22c0.01,0,0.01,0.01,0.02,0.01
          c0.04,0.03,0.08,0.07,0.12,0.09c0.06,0.03,0.12,0.08,0.17,0.12c0.02,0.01,0.04,0.02,0.05,0.03c0.12,0.05,0.25,0.1,0.38,0.13
          c0.01,0,0.03,0,0.04,0.01c0.03,0.02,0.07,0.02,0.11,0.03c0.02,0,0.05,0,0.07,0c0.01,0,0.03,0,0.04,0.01
          c0.03,0.03,0.08,0.03,0.11,0.04c0.01,0.01,0.03,0.01,0.05,0.01c0.07,0,0.14,0.02,0.22,0.03c0.04,0.01,0.08,0.02,0.13,0.01
          c0.04,0,0.08,0,0.13,0c0.05,0,0.1,0,0.15,0c0.03,0,0.06,0,0.08,0.01c0.04,0.01,0.08,0.01,0.11,0.02c0.07,0,0.12-0.01,0.18-0.02
          c0.06-0.01,0.1-0.05,0.13-0.09c0.03-0.04,0.04-0.1,0.06-0.15c0-0.02,0-0.04-0.01-0.05c-0.01-0.03-0.02-0.05-0.03-0.08
          c-0.01-0.01-0.01-0.03-0.02-0.04c-0.05-0.05-0.09-0.11-0.11-0.18c0-0.01-0.01-0.01-0.01-0.02c-0.01-0.02-0.03-0.04-0.04-0.06
          c-0.04-0.05-0.09-0.1-0.11-0.16c-0.04-0.03-0.06-0.09-0.09-0.13c-0.01-0.02-0.02-0.04-0.04-0.06c-0.06-0.08-0.13-0.16-0.19-0.23
          c-0.01-0.02-0.03-0.03-0.04-0.05c-0.03-0.03-0.05-0.06-0.07-0.1c-0.02-0.03-0.05-0.06-0.07-0.1c-0.05-0.02-0.07-0.06-0.09-0.1
          c-0.03-0.06-0.07-0.12-0.12-0.17c-0.02-0.02-0.04-0.03-0.04-0.05c-0.01-0.04-0.04-0.06-0.06-0.09c-0.03-0.04-0.06-0.09-0.09-0.13
          c-0.04-0.05-0.09-0.09-0.12-0.14c-0.01-0.02-0.03-0.03-0.05-0.05c-0.02-0.03-0.05-0.07-0.07-0.11c-0.01-0.01-0.02-0.03-0.03-0.04
          c-0.04-0.03-0.07-0.07-0.09-0.1c-0.02-0.03-0.04-0.06-0.06-0.08C52.99,2,52.93,1.94,52.88,1.88c-0.02-0.03-0.05-0.05-0.07-0.08
          c-0.02-0.03-0.05-0.06-0.08-0.08c-0.05-0.05-0.11-0.1-0.14-0.16c-0.01-0.01-0.01-0.02-0.02-0.03c-0.06-0.05-0.1-0.11-0.16-0.16
          c-0.05-0.04-0.09-0.09-0.13-0.14c-0.01-0.01-0.02-0.02-0.03-0.03c-0.02-0.05-0.08-0.07-0.1-0.12c0-0.01-0.01-0.01-0.02-0.01
          c-0.02-0.02-0.05-0.05-0.07-0.07c-0.02-0.02-0.04-0.04-0.06-0.06c-0.05-0.04-0.1-0.1-0.16-0.14c-0.01,0-0.01-0.01-0.01-0.01
          c-0.01-0.02-0.02-0.03-0.04-0.04c-0.07-0.04-0.13-0.09-0.18-0.14c-0.03-0.03-0.06-0.05-0.09-0.07c-0.03-0.02-0.06-0.04-0.08-0.07
          c-0.02-0.03-0.04-0.05-0.07-0.06c-0.01,0-0.02-0.01-0.04-0.02c-0.05-0.03-0.09-0.06-0.14-0.09c-0.05-0.03-0.11-0.06-0.16-0.1
          c-0.01-0.01-0.02-0.02-0.04-0.02c-0.06-0.02-0.12-0.04-0.18-0.05c-0.05-0.01-0.1-0.01-0.14,0.02c-0.02,0.01-0.04,0.02-0.07,0.03
          c-0.01,0.01-0.03,0.02-0.04,0.03c-0.03,0.05-0.06,0.1-0.05,0.17c0,0.01,0,0.03-0.01,0.03c-0.03,0.03-0.02,0.07-0.01,0.11
          C50.52,0.52,50.53,0.54,50.53,0.56z M40.28,12.78c0.02-0.04,0.05-0.08,0.08-0.12c0.02-0.03,0.04-0.06,0.05-0.1
          c0.02-0.06,0.05-0.11,0.08-0.16c0.05-0.08,0.09-0.15,0.14-0.23c0.08-0.13,0.15-0.27,0.21-0.42c0.07-0.16,0.13-0.33,0.19-0.5
          c0.04-0.11,0.06-0.23,0.09-0.35c0.02-0.08,0.04-0.17,0.05-0.26c0.02-0.14,0.03-0.28,0.05-0.41c0.01-0.05,0-0.1,0-0.15
          c0-0.03-0.01-0.05-0.03-0.08c-0.01-0.02-0.02-0.04-0.05-0.04c-0.03,0-0.07,0-0.1,0c-0.03,0-0.06-0.03-0.1-0.02
          C40.9,9.94,40.86,9.96,40.82,10c-0.05,0.05-0.09,0.12-0.15,0.17c0,0-0.01,0.01-0.01,0.01c-0.06,0.06-0.09,0.14-0.14,0.21
          c-0.02,0.02-0.03,0.05-0.05,0.07c-0.04,0.05-0.09,0.09-0.12,0.13c-0.04,0.05-0.09,0.11-0.12,0.17c-0.03,0.05-0.06,0.08-0.1,0.12
          c-0.03,0.03-0.05,0.07-0.07,0.11c-0.01,0.03-0.03,0.06-0.05,0.09c-0.02,0.02-0.03,0.05-0.04,0.07c-0.02,0.03-0.04,0.07-0.06,0.09
          c-0.06,0.06-0.1,0.13-0.16,0.18c-0.03,0.06-0.06,0.12-0.1,0.18c-0.02,0.11-0.09,0.21-0.12,0.32c0,0.01-0.01,0.02-0.01,0.02
          c-0.02,0.02-0.02,0.04-0.03,0.06c-0.01,0.05-0.02,0.11-0.06,0.15c-0.01,0.01-0.01,0.03-0.01,0.04c0,0.02-0.01,0.03-0.01,0.05
          c-0.04,0.08-0.04,0.16-0.07,0.24c-0.01,0.02-0.01,0.05-0.02,0.08c-0.01,0.04-0.01,0.07-0.01,0.11c0,0.01,0,0.02,0.01,0.03
          c0.01,0.03,0.02,0.06,0.01,0.09c0,0.06,0.01,0.11,0.03,0.15c0.01,0.03,0.02,0.06,0.02,0.1c0.01,0.04,0.01,0.08,0.02,0.12
          c0.01,0.06,0.05,0.1,0.11,0.12c0.03,0.01,0.06,0.03,0.09,0.05c0.03,0.02,0.07,0.04,0.11,0.02c0.01,0,0.02-0.01,0.03-0.01
          c0.07,0,0.12-0.04,0.17-0.09c0.04-0.04,0.08-0.08,0.11-0.13c0.01-0.01,0.02-0.02,0.02-0.03c0.01-0.01,0.02-0.02,0.03-0.03
          c0.02-0.04,0.04-0.07,0.07-0.11C40.18,12.92,40.23,12.85,40.28,12.78z M46.14,4.1c0.01,0.04,0.03,0.08,0.07,0.11
          c0.01,0.01,0.01,0.02,0.02,0.03c0.04,0.09,0.11,0.16,0.16,0.24c0.04,0.06,0.08,0.12,0.11,0.18c0.02,0.03,0.03,0.07,0.05,0.09
          c0.03,0.03,0.05,0.07,0.07,0.12c0.01,0.04,0.03,0.07,0.05,0.1c0.02,0.02,0.04,0.05,0.06,0.07c0.03,0.05,0.06,0.1,0.1,0.14
          c0.03,0.04,0.07,0.09,0.09,0.13c0.01,0.01,0.01,0.03,0.03,0.03c0.05,0.03,0.09,0.09,0.14,0.12c0.05,0.04,0.11,0.07,0.18,0.04
          c0.02-0.01,0.04,0,0.06-0.01c0.02,0,0.04,0,0.06-0.01c0.05-0.02,0.09-0.05,0.11-0.11c0.01-0.02,0.02-0.03,0.02-0.05
          c0.02-0.03,0.03-0.06,0.02-0.1c0-0.04-0.01-0.07-0.02-0.1c-0.01-0.03-0.01-0.07-0.01-0.11c-0.01-0.05-0.02-0.1-0.03-0.15
          c-0.01-0.05-0.03-0.1-0.03-0.15c0-0.04-0.01-0.09-0.02-0.13c-0.01-0.03-0.02-0.05,0-0.09c0-0.02-0.01-0.05-0.01-0.07
          c-0.01-0.03-0.02-0.06-0.02-0.1c-0.01-0.04-0.02-0.08-0.04-0.12c-0.02-0.02-0.02-0.05-0.03-0.08c0-0.06-0.03-0.12-0.06-0.17
          c-0.04-0.07-0.07-0.15-0.1-0.23c-0.03-0.09-0.06-0.17-0.11-0.25c0-0.01-0.01-0.02-0.01-0.03c0-0.05-0.03-0.09-0.05-0.13
          c-0.02-0.04-0.04-0.08-0.05-0.12c-0.03-0.08-0.06-0.16-0.09-0.23c-0.03-0.06-0.06-0.11-0.11-0.15c-0.02-0.02-0.04-0.03-0.06-0.03
          c-0.04,0-0.08,0-0.11,0.02c-0.06,0.03-0.11,0.07-0.14,0.12c-0.03,0.05-0.06,0.11-0.09,0.16c-0.05,0.08-0.1,0.16-0.15,0.24
          c-0.01,0.01-0.02,0.02-0.02,0.03c-0.01,0.05-0.04,0.08-0.06,0.12c-0.02,0.03-0.04,0.06-0.05,0.09c-0.01,0.05-0.02,0.11-0.03,0.16
          c0,0.05,0,0.11,0.02,0.16C46.09,3.95,46.12,4.02,46.14,4.1z M52.95,13.92c0-0.01,0-0.01-0.01-0.02c-0.02-0.02-0.03-0.05-0.03-0.08
          c0-0.01-0.01-0.02-0.01-0.03c-0.03-0.03-0.04-0.07-0.05-0.11c0-0.01-0.01-0.03-0.02-0.04c-0.05-0.06-0.07-0.15-0.12-0.21
          c0,0-0.01-0.01-0.01-0.02c-0.01-0.02-0.02-0.04-0.04-0.06c-0.04-0.05-0.07-0.09-0.1-0.14c-0.02-0.03-0.04-0.07-0.07-0.1
          c-0.03-0.03-0.06-0.07-0.07-0.11c-0.03-0.08-0.08-0.14-0.15-0.19c-0.06-0.05-0.11-0.11-0.14-0.18c-0.02-0.04-0.04-0.07-0.07-0.1
          c-0.03-0.03-0.07-0.06-0.09-0.1c-0.02-0.03-0.04-0.05-0.06-0.08c-0.04-0.05-0.1-0.09-0.13-0.15c-0.01-0.02-0.04-0.04-0.06-0.06
          c-0.02-0.02-0.04-0.04-0.05-0.06c-0.02-0.05-0.06-0.1-0.1-0.14c-0.03-0.03-0.06-0.07-0.08-0.11c-0.01-0.02-0.03-0.04-0.05-0.06
          c-0.06-0.07-0.12-0.14-0.19-0.21c-0.01-0.01-0.02-0.03-0.04-0.05c-0.03-0.05-0.07-0.09-0.1-0.14c-0.02-0.03-0.05-0.05-0.07-0.07
          c-0.05-0.06-0.1-0.11-0.16-0.16c-0.03-0.03-0.06-0.07-0.09-0.1c-0.02-0.03-0.04-0.05-0.07-0.08c-0.03-0.04-0.05-0.08-0.07-0.13
          c-0.01-0.02-0.03-0.04-0.04-0.06c-0.02-0.03-0.05-0.06-0.08-0.09c-0.03-0.03-0.05-0.06-0.07-0.09c-0.04-0.06-0.08-0.11-0.12-0.17
          c-0.04-0.06-0.09-0.12-0.15-0.17c-0.04-0.03-0.08-0.07-0.11-0.11c-0.04-0.06-0.07-0.12-0.11-0.18c-0.03-0.06-0.05-0.12-0.1-0.16
          c-0.01-0.01-0.02-0.02-0.02-0.04c-0.01-0.06-0.06-0.1-0.07-0.16c0-0.01-0.01-0.01-0.01-0.02c-0.02-0.02-0.02-0.04-0.02-0.06
          c0-0.02,0-0.03-0.01-0.05c-0.01-0.03-0.03-0.06-0.04-0.09c-0.02-0.04-0.03-0.09-0.05-0.12c-0.04-0.07-0.08-0.14-0.11-0.21
          c-0.04-0.12-0.09-0.24-0.11-0.36c0-0.02-0.02-0.04-0.02-0.07c-0.01-0.03-0.02-0.06-0.03-0.09c-0.01-0.04-0.02-0.07-0.03-0.11
          c-0.03-0.1-0.07-0.21-0.08-0.31c-0.01-0.08-0.02-0.17-0.03-0.25c0-0.04,0-0.07-0.02-0.1c-0.01-0.01-0.01-0.03-0.01-0.04
          c0.01-0.08,0-0.15,0-0.23c0-0.05,0-0.1-0.02-0.15c-0.02-0.09-0.02-0.19-0.04-0.28c-0.01-0.04-0.01-0.09,0-0.13
          c0.01-0.08,0-0.15,0-0.23c0-0.02,0-0.03,0-0.05c0.01-0.09,0.02-0.19,0-0.28c0-0.02,0-0.03,0-0.05c0-0.09,0-0.17,0-0.26
          c0-0.12,0-0.24,0.02-0.35c0-0.03,0-0.05,0-0.08c0-0.05-0.01-0.1,0-0.15c0-0.06,0.01-0.11,0-0.17c0-0.04,0-0.09,0-0.13
          c0-0.03,0-0.06,0-0.08c0-0.07,0-0.13,0-0.2c0-0.04,0-0.07,0-0.11c0-0.02,0-0.05,0.01-0.07c0.01-0.03,0.02-0.06,0.01-0.09
          c-0.02-0.04-0.01-0.09,0-0.13c0-0.02,0.01-0.03,0.01-0.05c0.01-0.03,0.01-0.06,0-0.08c-0.02-0.05-0.02-0.1-0.01-0.14
          c0-0.01,0-0.03,0-0.04c0-0.07-0.01-0.14,0.01-0.21c0.01-0.03,0-0.07-0.01-0.1c-0.01-0.02-0.02-0.04-0.01-0.07c0-0.05,0-0.1,0-0.15
          c0-0.04,0-0.08-0.02-0.12c-0.01-0.01-0.01-0.02-0.01-0.03c-0.01-0.08-0.02-0.16-0.02-0.24c0-0.03,0-0.06,0-0.08
          c-0.02-0.09-0.02-0.19-0.05-0.28c0-0.01,0-0.03,0-0.04c0-0.04,0-0.09-0.02-0.13c-0.02-0.05-0.03-0.09-0.04-0.14
          c-0.02-0.08-0.04-0.16-0.04-0.25c0-0.02,0-0.04-0.01-0.05c-0.01-0.02-0.02-0.04-0.02-0.07c-0.01-0.05-0.03-0.1-0.07-0.13
          c-0.03-0.03-0.05-0.06-0.07-0.1c-0.04-0.11-0.09-0.21-0.15-0.31c-0.05-0.08-0.09-0.15-0.14-0.23c-0.04-0.06-0.08-0.11-0.14-0.16
          c-0.02-0.02-0.04-0.03-0.06-0.05c-0.02-0.04-0.05-0.07-0.09-0.09c-0.01,0-0.01-0.01-0.02-0.02c-0.02-0.02-0.04-0.04-0.05-0.06
          C48.06,1.02,48,1,47.96,1c-0.04,0.01-0.08,0.02-0.12,0.03c-0.04,0.01-0.08,0.03-0.11,0.06c-0.02,0.02-0.04,0.04-0.07,0.05
          c-0.04,0.03-0.07,0.06-0.09,0.1c-0.01,0.01-0.02,0.02-0.03,0.03c-0.03,0.03-0.06,0.06-0.09,0.09C47.4,1.43,47.34,1.5,47.3,1.58
          c-0.01,0.03-0.03,0.06-0.07,0.07c-0.01,0-0.01,0.01-0.02,0.01c-0.04,0.04-0.07,0.08-0.11,0.12c-0.04,0.05-0.05,0.09,0,0.14
          c0,0,0.01,0.01,0.01,0.02c0,0.01-0.01,0.02-0.01,0.04c-0.02,0.07-0.02,0.13,0,0.2c0.02,0.05,0.03,0.1,0.04,0.14
          c0.01,0.03,0.02,0.07,0.05,0.09c0.03,0.03,0.05,0.07,0.06,0.11c0.03,0.08,0.06,0.16,0.09,0.24c0.02,0.04,0.04,0.08,0.04,0.12
          c0,0.04,0.02,0.08,0.04,0.11c0.02,0.03,0.03,0.05,0.04,0.08c0.02,0.05,0.04,0.1,0.08,0.14c0.01,0.01,0.02,0.03,0.02,0.04
          c0.02,0.04,0.02,0.09,0.05,0.13c0.01,0.06,0.04,0.12,0.06,0.17c0,0.01,0,0.02,0.01,0.03c0.04,0.04,0.05,0.1,0.08,0.14
          c0.03,0.04,0.03,0.09,0.05,0.14c0.03,0.07,0.06,0.13,0.08,0.2C47.94,4.18,48,4.28,48,4.4c0.02,0.08,0.02,0.16,0.03,0.24
          c0.01,0.04,0.01,0.07,0.01,0.11c0,0.05,0.02,0.11,0.04,0.15c0.01,0.02,0.02,0.04,0.01,0.07c-0.01,0.04-0.01,0.08-0.01,0.12
          c0.01,0.09-0.02,0.17-0.02,0.26c0,0.02-0.01,0.04-0.02,0.05C48.01,5.48,48,5.57,47.99,5.66c0,0.03-0.01,0.05-0.03,0.07
          c-0.04,0.04-0.07,0.08-0.08,0.13c0,0.02-0.01,0.04-0.02,0.06c-0.01,0.02-0.01,0.04-0.03,0.05c-0.02,0.02-0.04,0.03-0.07,0.05
          c-0.05,0.03-0.1,0.04-0.15,0.07c-0.09,0.01-0.17,0.04-0.26,0.05c-0.06,0-0.12,0-0.18-0.03c-0.02-0.01-0.05-0.02-0.07-0.03
          c-0.05-0.01-0.09-0.03-0.13-0.05C46.96,6.02,46.95,6.01,46.93,6c-0.05-0.03-0.11-0.05-0.15-0.1c-0.02-0.02-0.04-0.03-0.06-0.04
          c-0.05-0.02-0.09-0.05-0.13-0.09c-0.02-0.02-0.04-0.03-0.05-0.06c-0.01-0.02-0.02-0.03-0.04-0.04c-0.02-0.02-0.03-0.03-0.05-0.05
          c-0.03-0.04-0.06-0.07-0.09-0.11c-0.04-0.04-0.08-0.09-0.11-0.14c-0.04-0.06-0.07-0.11-0.11-0.17c-0.02-0.02-0.03-0.05-0.04-0.08
          c-0.01-0.04-0.02-0.08-0.03-0.12c-0.02-0.06-0.04-0.12-0.09-0.17c-0.04-0.04-0.06-0.08-0.06-0.14c0-0.01,0-0.02-0.01-0.03
          c-0.02-0.03-0.02-0.06-0.03-0.09c0-0.01-0.01-0.03-0.02-0.04c-0.05-0.03-0.09-0.07-0.15-0.07c-0.06,0-0.11,0-0.17,0
          c-0.04,0-0.08,0.01-0.11,0.02c-0.06,0.03-0.11,0.06-0.15,0.12c-0.03,0.05-0.07,0.1-0.1,0.14c-0.02,0.02-0.04,0.05-0.06,0.07
          c-0.02,0.02-0.05,0.04-0.07,0.07c-0.07,0.11-0.13,0.23-0.19,0.35c-0.01,0.02-0.02,0.04-0.04,0.06c-0.03,0.03-0.05,0.07-0.07,0.11
          c-0.02,0.04-0.03,0.08-0.04,0.13c-0.01,0.03-0.01,0.07-0.01,0.1c0,0.05,0,0.09,0,0.14c0,0.03,0.01,0.06,0.04,0.09
          c0.03,0.06,0.07,0.11,0.11,0.17c0.03,0.05,0.06,0.1,0.1,0.15c0.01,0.01,0.01,0.03,0.02,0.04c0.01,0.04,0.03,0.07,0.06,0.1
          c0.04,0.04,0.07,0.08,0.1,0.13c0.01,0.02,0.02,0.03,0.03,0.04c0.02,0.02,0.05,0.04,0.07,0.06c0.02,0.04,0.06,0.07,0.08,0.1
          c0.02,0.02,0.03,0.05,0.05,0.07c0.01,0.01,0.02,0.03,0.04,0.04c0.03,0.03,0.06,0.06,0.1,0.08c0.05,0.02,0.09,0.06,0.13,0.1
          c0.02,0.01,0.03,0.03,0.05,0.04c0.04,0.03,0.09,0.06,0.13,0.1c0.05,0.04,0.09,0.08,0.15,0.11c0.02,0.01,0.03,0.02,0.04,0.03
          c0.03,0.02,0.06,0.05,0.09,0.07c0.03,0.02,0.07,0.05,0.1,0.07c0.06,0.05,0.12,0.1,0.18,0.16c0.04,0.03,0.08,0.06,0.11,0.1
          c0.04,0.04,0.08,0.07,0.12,0.09c0.02,0.01,0.04,0.02,0.06,0.04c0.02,0.01,0.04,0.02,0.05,0.04c0.03,0.06,0.08,0.09,0.13,0.12
          c0.06,0.04,0.12,0.08,0.19,0.1c0.02,0.01,0.04,0.02,0.06,0.04c0.02,0.03,0.06,0.05,0.09,0.07c0.05,0.03,0.1,0.05,0.14,0.1
          c0.01,0.02,0.04,0.03,0.06,0.04c0.05,0.03,0.1,0.05,0.14,0.09c0.01,0.01,0.02,0.01,0.03,0.02c0.05,0.04,0.11,0.08,0.16,0.13
          c0.03,0.03,0.07,0.05,0.09,0.09c0.04,0.04,0.08,0.08,0.13,0.12c0.04,0.03,0.08,0.06,0.11,0.1c0.02,0.03,0.05,0.05,0.07,0.08
          c0.02,0.02,0.05,0.04,0.06,0.07c0,0.01,0.01,0.01,0.01,0.02c0.05,0.02,0.07,0.06,0.11,0.09c0.02,0.01,0.03,0.03,0.05,0.05
          c0.02,0.03,0.04,0.06,0.06,0.09c0.01,0.02,0.02,0.04,0.03,0.05c0.02,0.03,0.04,0.07,0.07,0.1c0.03,0.04,0.05,0.07,0.08,0.11
          c0.04,0.05,0.08,0.11,0.12,0.16c0.07,0.08,0.12,0.17,0.18,0.26c0.03,0.05,0.05,0.1,0.09,0.14c0.01,0.01,0.02,0.03,0.03,0.05
          c0.02,0.02,0.03,0.05,0.05,0.07c0.02,0.02,0.04,0.04,0.05,0.07c0.05,0.07,0.1,0.14,0.13,0.23c0,0.01,0.01,0.01,0.01,0.02
          c0.04,0.05,0.06,0.1,0.09,0.16c0.01,0.01,0.01,0.03,0.02,0.04c0.04,0.04,0.06,0.09,0.08,0.13c0.03,0.06,0.05,0.13,0.08,0.19
          c0.02,0.03,0.03,0.07,0.03,0.11c0,0.02,0.01,0.04,0.02,0.05c0.03,0.06,0.06,0.13,0.07,0.2c0,0.02,0,0.05,0.01,0.07
          c0.02,0.06,0.04,0.11,0.06,0.16c0.02,0.05,0.04,0.1,0.04,0.15c0,0.01,0,0.03,0.01,0.04c0.04,0.06,0.07,0.13,0.08,0.21
          c0,0.01,0.01,0.01,0.01,0.02c0.01,0.03,0.02,0.07,0.03,0.1c0.01,0.04,0.01,0.07,0.03,0.1c0.01,0.02,0.02,0.05,0.03,0.07
          c0.01,0.07,0.05,0.13,0.05,0.2c0,0,0,0.01,0,0.01c0.03,0.05,0.03,0.1,0.04,0.15c0.01,0.05,0.03,0.1,0.05,0.16
          c0,0.01,0,0.02,0.01,0.03c0,0.01,0,0.02,0.01,0.03c0.02,0.05,0.04,0.1,0.05,0.16c0.01,0.03,0.02,0.06,0.02,0.1
          c0,0.01,0,0.02,0.01,0.03c0.02,0.04,0.02,0.08,0.03,0.11c0.02,0.07,0.03,0.13,0.02,0.2c0,0.01,0,0.01,0,0.02
          c0.01,0.07,0.01,0.14,0.03,0.2c0.01,0.02,0.01,0.05,0.02,0.07c0,0.01,0.01,0.03,0,0.04c-0.01,0.03,0,0.07,0.01,0.1
          c0.02,0.05,0.03,0.1,0.07,0.13c0.01,0.01,0.02,0.02,0.02,0.04c0.03,0.08,0.08,0.12,0.15,0.14c0.05,0.02,0.1,0.03,0.15,0.05
          c0.08,0.03,0.15,0.05,0.23,0.05c0.04,0,0.08,0.01,0.13,0.01c0.01,0,0.02,0,0.04,0c0.04-0.01,0.09,0,0.13,0
          c0.02,0,0.04,0.01,0.05,0.01c0.01,0,0.03,0,0.04,0c0.03-0.01,0.07-0.01,0.1-0.01c0.1-0.01,0.19,0,0.29,0
          c0.02,0,0.04,0.01,0.06,0.01c0.07,0.02,0.13,0.03,0.2,0.02c0.01,0,0.03,0,0.04,0c0.03,0.01,0.05,0.01,0.08,0.02
          c0.05,0.01,0.1,0.01,0.15,0.03c0.03,0.01,0.06,0.01,0.1,0.01c0.03,0,0.05,0,0.08,0c0.08,0.01,0.16,0.02,0.24,0.01
          c0.01,0,0.02,0,0.03,0c0.06,0.01,0.11-0.02,0.16-0.03c0.02-0.01,0.05-0.02,0.06-0.04c0.02-0.02,0.03-0.06,0.05-0.08
          c0.01-0.01,0.01-0.03,0.02-0.05c0.03-0.05,0.04-0.1,0.02-0.15C52.96,13.99,52.94,13.96,52.95,13.92z M49.75,13.85
          c0-0.03,0-0.05,0-0.08c-0.01-0.07-0.02-0.15-0.06-0.21c-0.02-0.03-0.03-0.08-0.03-0.12c0-0.02-0.01-0.04-0.01-0.06
          c-0.01-0.03-0.02-0.06-0.04-0.09c-0.04-0.08-0.06-0.17-0.08-0.26c-0.01-0.05-0.02-0.1-0.04-0.14c-0.01-0.05-0.05-0.1-0.05-0.16
          c-0.05-0.15-0.08-0.31-0.11-0.47c-0.01-0.03-0.01-0.06-0.02-0.08c-0.03-0.08-0.04-0.16-0.06-0.24c-0.01-0.05-0.02-0.09-0.04-0.13
          c-0.01-0.02-0.02-0.04-0.03-0.06c-0.03-0.07-0.04-0.15-0.09-0.21c0,0-0.01-0.01-0.01-0.02c-0.01-0.05-0.03-0.1-0.05-0.15
          c-0.02-0.05-0.03-0.1-0.06-0.14c-0.02-0.04-0.05-0.08-0.05-0.12c0-0.01-0.01-0.02-0.01-0.03c-0.01-0.03-0.03-0.06-0.04-0.09
          c-0.01-0.03-0.02-0.07-0.04-0.1c-0.02-0.03-0.04-0.06-0.05-0.09c-0.02-0.06-0.05-0.11-0.08-0.16c-0.02-0.03-0.03-0.06-0.05-0.09
          c-0.01-0.02-0.02-0.03-0.03-0.05c-0.04-0.07-0.09-0.13-0.12-0.2c-0.02-0.04-0.05-0.09-0.08-0.13c-0.02-0.03-0.05-0.07-0.07-0.11
          c-0.02-0.04-0.04-0.08-0.07-0.11c-0.02-0.02-0.03-0.04-0.04-0.06c-0.01-0.04-0.04-0.06-0.06-0.09c-0.04-0.05-0.08-0.09-0.12-0.14
          c-0.03-0.04-0.06-0.07-0.1-0.11c-0.01-0.01-0.02-0.02-0.03-0.02c-0.04-0.03-0.07-0.06-0.11-0.09c-0.02-0.02-0.05-0.04-0.06-0.07
          c-0.01-0.02-0.03-0.04-0.04-0.05c-0.05-0.04-0.1-0.09-0.14-0.14c-0.02-0.03-0.04-0.05-0.07-0.07c-0.04-0.04-0.08-0.08-0.12-0.12
          c-0.01-0.02-0.03-0.03-0.05-0.04c-0.04-0.04-0.08-0.07-0.12-0.11c-0.03-0.03-0.06-0.06-0.1-0.09c-0.08-0.05-0.14-0.11-0.22-0.16
          c-0.08-0.04-0.13-0.12-0.21-0.16c-0.04-0.05-0.1-0.08-0.14-0.12c-0.01-0.01-0.02-0.02-0.03-0.02c-0.09-0.06-0.18-0.11-0.28-0.17
          c-0.01-0.01-0.02-0.02-0.03-0.02c-0.01-0.01-0.03-0.03-0.04-0.04c-0.06-0.04-0.13-0.08-0.18-0.13C46.01,7.92,46,7.92,45.99,7.91
          c-0.08-0.05-0.16-0.1-0.24-0.15C45.7,7.73,45.64,7.7,45.6,7.65c-0.04-0.05-0.09-0.09-0.15-0.12c-0.07-0.06-0.15-0.12-0.22-0.19
          c-0.03-0.03-0.07-0.06-0.1-0.1c-0.03-0.03-0.06-0.07-0.1-0.1c-0.05-0.04-0.1-0.08-0.14-0.14c0-0.01-0.01-0.01-0.02-0.01
          c-0.02-0.02-0.04-0.04-0.06-0.06c-0.01-0.01-0.02-0.03-0.04-0.04c-0.06-0.03-0.1-0.08-0.12-0.14c-0.02-0.04-0.04-0.07-0.07-0.1
          c-0.03-0.03-0.06-0.06-0.08-0.1c0-0.01-0.01-0.01-0.01-0.02c-0.04-0.04-0.09-0.07-0.14-0.09c-0.07-0.03-0.14-0.01-0.21,0.01
          c-0.03,0.03-0.07,0.04-0.11,0.06c-0.02,0.01-0.04,0.03-0.05,0.05c-0.03,0.04-0.06,0.09-0.08,0.13c-0.01,0.03-0.03,0.05-0.05,0.07
          c-0.04,0.05-0.07,0.09-0.1,0.14c-0.03,0.04-0.06,0.08-0.08,0.11c-0.01,0.01-0.02,0.03-0.04,0.04c-0.05,0.03-0.09,0.07-0.13,0.12
          c-0.02,0.02-0.03,0.05-0.04,0.08c-0.01,0.03-0.02,0.06-0.04,0.09c-0.02,0.04-0.02,0.09-0.01,0.13c0.01,0.03,0.02,0.06,0.05,0.08
          c0.02,0.01,0.03,0.03,0.05,0.05c0.03,0.03,0.05,0.06,0.07,0.1c0.03,0.09,0.08,0.16,0.12,0.24c0.03,0.05,0.07,0.1,0.09,0.16
          c0.01,0.02,0.02,0.03,0.03,0.04c0.02,0.02,0.04,0.03,0.04,0.05c0.01,0.03,0.03,0.05,0.05,0.07c0.01,0.01,0.02,0.03,0.03,0.05
          c0.04,0.06,0.08,0.13,0.14,0.18c0.02,0.02,0.04,0.04,0.05,0.07c0.01,0.02,0.02,0.04,0.04,0.06c0.06,0.05,0.11,0.11,0.16,0.16
          c0.03,0.03,0.06,0.06,0.1,0.09c0.02,0.01,0.04,0.03,0.05,0.05c0.03,0.03,0.06,0.06,0.09,0.09c0.01,0.01,0.03,0.02,0.04,0.04
          c0.03,0.05,0.08,0.08,0.12,0.12c0.04,0.03,0.07,0.06,0.11,0.09c0.01,0.01,0.03,0.03,0.04,0.04c0.06,0.04,0.12,0.09,0.18,0.12
          c0.04,0.03,0.09,0.04,0.14,0.06c0.09,0.04,0.19,0.08,0.28,0.14c0.03,0.02,0.06,0.03,0.09,0.05c0.04,0.02,0.08,0.04,0.11,0.06
          c0.02,0.01,0.05,0.03,0.07,0.03c0.09,0.02,0.18,0.06,0.26,0.1c0.03,0.01,0.06,0.02,0.09,0.03c0.03,0.01,0.06,0.01,0.08,0.02
          c0.07,0.02,0.14,0.04,0.19,0.09c0.01,0.01,0.03,0.02,0.05,0.02c0.03,0.01,0.06,0.03,0.08,0.05c0.03,0.02,0.06,0.04,0.09,0.06
          c0.04,0.03,0.08,0.05,0.11,0.09c0.04,0.05,0.08,0.09,0.12,0.13c0.06,0.07,0.11,0.14,0.15,0.22c0.03,0.05,0.06,0.11,0.1,0.15
          c0.04,0.04,0.07,0.09,0.09,0.14c0.01,0.02,0.02,0.05,0.03,0.06c0.04,0.06,0.09,0.12,0.12,0.18c0.01,0.02,0.03,0.03,0.04,0.05
          c0.02,0.03,0.03,0.06,0.05,0.09c0.04,0.09,0.09,0.17,0.16,0.24c0.03,0.03,0.04,0.07,0.05,0.11c0.01,0.03,0.03,0.06,0.04,0.09
          c0.01,0.02,0.02,0.04,0.03,0.05c0.03,0.05,0.05,0.11,0.09,0.16c0.01,0.01,0.01,0.02,0.02,0.03c0.02,0.04,0.03,0.08,0.05,0.12
          c0.01,0.03,0.03,0.07,0.05,0.09c0.01,0.01,0.01,0.02,0.02,0.04c0.01,0.06,0.04,0.11,0.07,0.16c0.04,0.07,0.08,0.13,0.11,0.21
          c0.02,0.06,0.06,0.12,0.08,0.19c0,0.01,0.01,0.02,0.02,0.02c0.04,0.05,0.08,0.09,0.09,0.16c0,0.02,0.01,0.04,0.02,0.06
          c0.03,0.05,0.05,0.11,0.06,0.17c0,0.01,0.01,0.03,0.01,0.04c0.03,0.03,0.03,0.07,0.05,0.1c0.01,0.04,0.03,0.08,0.05,0.13
          c0.02,0.03,0.04,0.06,0.04,0.1c0,0.02,0.02,0.05,0.03,0.07c0.01,0.02,0.02,0.03,0.02,0.05c0.01,0.06,0.03,0.11,0.03,0.17
          c0,0.02,0.01,0.05,0.02,0.07c0.01,0.02,0.02,0.03,0.02,0.04c0.04,0.1,0.12,0.17,0.2,0.23c0.05,0.04,0.11,0.07,0.16,0.1
          c0.01,0.01,0.03,0.01,0.04,0.02c0.05,0.01,0.09,0.02,0.14,0.02c0.04,0,0.08,0,0.11,0c0,0,0,0,0,0c0.06,0,0.12,0,0.18-0.01
          c0.02,0,0.03-0.01,0.05-0.01c0.07-0.02,0.13-0.05,0.18-0.1c0.04-0.04,0.08-0.08,0.07-0.15c0-0.04-0.01-0.07-0.02-0.11
          C49.76,13.88,49.76,13.87,49.75,13.85z M61.62,12.63c-0.01-0.04-0.02-0.08-0.02-0.12c0-0.09-0.03-0.18-0.04-0.26
          c0-0.01-0.01-0.02-0.01-0.03c-0.04-0.08-0.07-0.17-0.08-0.26c0-0.01-0.01-0.02-0.01-0.03c-0.03-0.05-0.06-0.09-0.09-0.14
          c-0.01-0.02-0.04-0.04-0.05-0.06c-0.02-0.04-0.04-0.07-0.07-0.09c-0.02-0.02-0.04-0.05-0.06-0.08c-0.01-0.03-0.04-0.05-0.06-0.07
          c-0.05-0.05-0.1-0.09-0.15-0.15c-0.04-0.05-0.09-0.1-0.14-0.14c-0.02-0.01-0.04-0.03-0.06-0.05c-0.06-0.07-0.13-0.13-0.22-0.17
          c-0.01-0.01-0.02-0.02-0.03-0.03c-0.05-0.04-0.08-0.1-0.14-0.12c-0.07-0.03-0.13-0.08-0.18-0.13c-0.09-0.08-0.18-0.17-0.26-0.26
          c-0.07-0.08-0.15-0.15-0.22-0.23c-0.13-0.14-0.27-0.28-0.4-0.43c-0.06-0.07-0.12-0.13-0.18-0.2c-0.03-0.04-0.07-0.07-0.1-0.11
          c-0.07-0.07-0.13-0.13-0.18-0.22c-0.01-0.01-0.02-0.02-0.03-0.03c-0.03-0.04-0.06-0.08-0.07-0.12c-0.01-0.01-0.01-0.03-0.03-0.04
          c-0.06-0.07-0.11-0.15-0.19-0.2c-0.01,0-0.01-0.01-0.01-0.02c-0.02-0.04-0.06-0.06-0.1-0.07c-0.12-0.03-0.23-0.01-0.31,0.1
          c-0.01,0.02-0.03,0.03-0.05,0.04c-0.02,0.01-0.03,0.03-0.04,0.05c-0.01,0.05-0.03,0.11-0.02,0.17c0,0.02,0,0.03,0,0.05
          C58,9.25,57.99,9.31,57.97,9.38c0,0.01,0,0.01,0,0.02c0.01,0.05,0.01,0.1-0.01,0.15c0,0.01,0,0.02,0,0.03
          c0.01,0.05,0,0.11-0.01,0.16c-0.01,0.1-0.03,0.19-0.02,0.29c0,0.01,0,0.01,0,0.02c-0.02,0.06-0.02,0.12-0.01,0.18
          c0.01,0.05-0.01,0.1,0.01,0.15c0,0.01,0,0.02,0,0.03c0,0.01,0,0.03,0,0.04c0.02,0.04,0.01,0.09,0.04,0.13
          c0.01,0.01,0.01,0.03,0.01,0.05c0,0.04,0,0.09,0.02,0.13c0.01,0.02,0.01,0.04,0.01,0.06c0,0.06,0.02,0.11,0.04,0.17
          c0.04,0.08,0.04,0.18,0.1,0.26c0,0,0,0.01,0,0.01c0.01,0.03,0.02,0.07,0.04,0.1c0.03,0.05,0.05,0.09,0.05,0.14
          c0,0.03,0.01,0.05,0.04,0.07c0.01,0.01,0.02,0.02,0.03,0.04c0.03,0.06,0.07,0.11,0.1,0.17c0.04,0.06,0.06,0.12,0.11,0.17
          c0.01,0.01,0.02,0.03,0.03,0.05c0.03,0.06,0.08,0.1,0.11,0.15c0.01,0.01,0.02,0.01,0.03,0.02c0.02,0.02,0.04,0.03,0.05,0.05
          c0.03,0.06,0.08,0.09,0.11,0.14c0.02,0.02,0.04,0.04,0.06,0.05c0.03,0.01,0.05,0.04,0.07,0.07c0.01,0.02,0.03,0.04,0.05,0.05
          c0.05,0.03,0.1,0.06,0.13,0.1c0.01,0.01,0.01,0.01,0.02,0.02c0.05,0.03,0.1,0.08,0.16,0.1c0.01,0,0.02,0.01,0.02,0.02
          c0.05,0.05,0.11,0.07,0.16,0.11c0.06,0.04,0.13,0.06,0.18,0.1c0.01,0.01,0.03,0.01,0.04,0.02c0.01,0.01,0.03,0.02,0.04,0.03
          c0.06,0.05,0.13,0.06,0.19,0.1c0.11,0.03,0.21,0.09,0.32,0.12c0.05,0.02,0.11,0.03,0.15,0.07c0.01,0.01,0.02,0.01,0.03,0.01
          c0.09,0.03,0.18,0.06,0.28,0.08c0.07,0.02,0.14,0.04,0.22,0.04c0.06-0.01,0.13-0.01,0.19-0.02c0.02,0,0.04-0.01,0.05-0.02
          c0.05-0.03,0.1-0.06,0.14-0.11c0.07-0.09,0.12-0.2,0.15-0.31c0.01-0.03,0.01-0.07,0.02-0.1c0.03-0.07,0.02-0.15,0.04-0.23
          C61.62,12.66,61.62,12.64,61.62,12.63z M60.39,13.86c-0.01,0-0.02-0.02-0.02-0.03c-0.02-0.03-0.05-0.05-0.08-0.06
          c-0.04-0.01-0.08-0.01-0.13-0.02c-0.03,0-0.07,0-0.1-0.01c-0.06-0.02-0.13-0.03-0.19-0.07c-0.03-0.02-0.07-0.03-0.1-0.05
          c-0.04-0.02-0.07-0.05-0.11-0.07c-0.03-0.02-0.07-0.04-0.1-0.05c-0.04-0.02-0.07-0.03-0.11-0.05c-0.05-0.03-0.11-0.07-0.15-0.11
          c-0.05-0.04-0.1-0.07-0.16-0.08c-0.02,0-0.03,0-0.05-0.01c-0.02-0.01-0.04-0.02-0.06-0.03c-0.04-0.03-0.08-0.06-0.12-0.09
          c-0.04-0.03-0.09-0.07-0.14-0.09c-0.05-0.02-0.09-0.05-0.12-0.09c-0.03-0.03-0.05-0.06-0.08-0.1c-0.02-0.02-0.03-0.04-0.05-0.05
          c-0.03-0.01-0.04-0.03-0.06-0.06c-0.01-0.02-0.02-0.03-0.04-0.04c-0.02-0.03-0.05-0.05-0.07-0.08c-0.01-0.01-0.02-0.02-0.02-0.03
          c-0.03-0.02-0.05-0.05-0.08-0.07c-0.03-0.02-0.06-0.05-0.09-0.07c-0.02-0.01-0.04-0.03-0.05-0.05c-0.03-0.05-0.06-0.1-0.09-0.14
          c-0.05-0.08-0.1-0.17-0.16-0.25c-0.05-0.06-0.08-0.13-0.11-0.2c-0.04-0.1-0.08-0.19-0.11-0.3c-0.01-0.05-0.04-0.1-0.05-0.15
          c0-0.02-0.01-0.04-0.01-0.05c0-0.02-0.01-0.04-0.02-0.06c-0.03-0.05-0.06-0.1-0.07-0.16c-0.01-0.06-0.03-0.13-0.04-0.19
          c0-0.04-0.02-0.08,0-0.13c0-0.01-0.01-0.02-0.01-0.03c-0.03-0.03-0.03-0.07-0.03-0.11c0-0.05-0.01-0.11,0-0.16
          c0-0.03-0.02-0.05-0.02-0.08c0-0.03,0.01-0.06,0.01-0.09c0-0.04,0-0.08-0.01-0.13c0-0.04-0.01-0.08-0.01-0.13
          c0-0.04-0.01-0.08-0.01-0.11c0-0.03,0.01-0.06,0.01-0.09c0.01-0.06,0.03-0.13,0.04-0.19c0.01-0.08,0.03-0.17,0.02-0.25
          c0-0.03,0.01-0.05,0.01-0.08c0.01-0.05,0.02-0.11,0.03-0.16c0.03-0.12,0.05-0.25,0.04-0.38c0-0.01,0-0.03,0.01-0.04
          c0.02-0.04,0.03-0.09,0.05-0.13c0.01-0.02,0.01-0.04,0.01-0.06c-0.01-0.05,0.01-0.08,0.03-0.12c0.02-0.05,0.03-0.09,0.02-0.14
          c-0.01-0.03-0.01-0.05,0-0.08c0.01-0.04,0.01-0.08,0.01-0.12c0-0.02,0-0.04,0-0.06c0.01-0.03,0-0.05-0.01-0.07
          c-0.01-0.02-0.03-0.04-0.04-0.07c-0.01-0.03-0.02-0.05-0.04-0.07c-0.01-0.02-0.02-0.04-0.04-0.06c-0.01-0.02-0.03-0.04-0.04-0.06
          c-0.01-0.02-0.02-0.03-0.03-0.05c-0.03-0.04-0.05-0.07-0.08-0.11c-0.06-0.06-0.12-0.12-0.17-0.19c-0.08-0.09-0.16-0.18-0.23-0.26
          c-0.02-0.02-0.03-0.03-0.05-0.05c-0.05-0.06-0.1-0.12-0.15-0.18c-0.06-0.06-0.11-0.13-0.17-0.19c-0.04-0.04-0.07-0.09-0.11-0.14
          c-0.05-0.05-0.1-0.1-0.15-0.15c-0.02-0.02-0.04-0.03-0.05-0.05c-0.06-0.11-0.16-0.19-0.22-0.29c-0.06-0.06-0.09-0.13-0.15-0.19
          c-0.05-0.04-0.08-0.1-0.12-0.15c-0.04-0.06-0.09-0.11-0.12-0.18c-0.01-0.02-0.02-0.04-0.04-0.05c-0.04-0.05-0.08-0.09-0.11-0.14
          c-0.01-0.02-0.02-0.03-0.04-0.04c-0.03-0.02-0.05-0.05-0.07-0.08c-0.02-0.04-0.06-0.05-0.1-0.06c-0.04-0.01-0.07-0.02-0.11-0.03
          c-0.03-0.01-0.06-0.01-0.09-0.01c-0.02,0-0.05,0.01-0.07,0.01c-0.05,0-0.1,0-0.16-0.01c-0.03,0-0.06,0-0.1,0.01
          c-0.03,0.01-0.07,0.01-0.1,0.01c-0.04,0-0.08,0-0.13,0c-0.07,0-0.15,0.01-0.22-0.01c-0.04-0.01-0.08-0.01-0.13,0
          c-0.12,0.02-0.23,0.04-0.35,0c-0.01,0-0.01,0-0.02,0c-0.1,0-0.19,0-0.29-0.01c-0.06,0-0.12,0-0.18-0.01
          c-0.06-0.01-0.12,0-0.17,0.02c-0.05,0.01-0.09,0.07-0.08,0.12c0.01,0.05,0.01,0.1,0.02,0.15c0.01,0.03,0.01,0.07-0.01,0.1
          c-0.01,0.02-0.01,0.04,0,0.06c0.02,0.05,0.03,0.1,0.03,0.15c0,0.05,0.03,0.1,0.03,0.15c0.03,0.04,0.02,0.09,0.02,0.13
          c0,0.02,0,0.04,0.01,0.06c0.02,0.04,0.04,0.08,0.03,0.13c0,0.01,0,0.03,0,0.04c0.01,0.03,0.01,0.06,0,0.09
          c-0.03,0.11-0.01,0.21,0.02,0.31c0.01,0.03,0.02,0.05,0.03,0.08c0.02,0.04,0.04,0.09,0.04,0.13c0,0.02,0.01,0.05,0.03,0.07
          c0.02,0.02,0.03,0.05,0.04,0.08c0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.02,0.02,0.04,0.02,0.07c0,0.04,0.01,0.08,0.02,0.12
          c0.01,0.02,0.03,0.04,0.04,0.06c0.01,0.01,0.01,0.03,0.02,0.04c0.01,0.01,0.01,0.03,0.02,0.04c0.05,0.06,0.1,0.12,0.16,0.17
          c0.01,0.01,0.02,0.01,0.03,0.02c0.05,0.01,0.09,0.03,0.13,0.05c0.07,0.04,0.15,0.05,0.24,0.06c0.02,0,0.04,0,0.06,0
          c0.1,0.02,0.2,0.05,0.29,0.1c0.03,0.02,0.06,0.03,0.1,0.04c0.01,0.01,0.03,0.01,0.04,0.02c0.08,0.07,0.17,0.12,0.26,0.19
          c0.02,0.01,0.03,0.03,0.04,0.04C54.95,8.1,54.97,8.14,55,8.17c0.03,0.04,0.04,0.08,0.09,0.11c0.02,0.02,0.04,0.04,0.05,0.06
          c0.01,0.04,0.04,0.07,0.06,0.1c0.02,0.04,0.05,0.08,0.08,0.12c0.01,0.02,0.02,0.03,0.04,0.04c0.02,0.01,0.04,0.03,0.05,0.05
          c0.01,0.02,0.02,0.04,0.03,0.05c0.01,0.01,0.02,0.02,0.02,0.04c0,0.04,0.02,0.06,0.04,0.09c0.03,0.04,0.05,0.08,0.06,0.12
          c0.01,0.04,0.03,0.08,0.06,0.12c0.01,0.01,0.02,0.03,0.02,0.04c0,0.03,0.02,0.05,0.04,0.07c0.03,0.04,0.06,0.07,0.06,0.13
          c0,0.02,0.02,0.05,0.02,0.07c0.01,0.02,0.02,0.05,0.02,0.07c0,0.02,0.01,0.04,0.02,0.06c0.02,0.03,0.02,0.06,0.03,0.09
          c0,0.05,0.01,0.11,0.03,0.16c0.01,0.02,0.01,0.05,0.01,0.08c0.01,0.08,0,0.16,0.03,0.23c0.01,0.02,0.01,0.04,0.01,0.05
          c0.01,0.06,0.01,0.13,0.01,0.19c0,0.02,0,0.04-0.01,0.05c-0.02,0.06-0.04,0.12-0.03,0.19c0.01,0.03-0.01,0.05-0.02,0.08
          c0,0.03,0.01,0.06,0,0.09c-0.03,0.11-0.08,0.22-0.14,0.32c-0.02,0.02-0.02,0.05-0.02,0.08c0.01,0.05,0,0.09-0.01,0.14
          c-0.01,0.03,0,0.06,0.01,0.09c0.01,0.03,0.03,0.06,0.05,0.08c0.02,0.02,0.03,0.04,0.03,0.06c0.02,0.09,0.08,0.16,0.13,0.24
          c0.01,0.02,0.03,0.02,0.04,0.04c0.03,0.06,0.08,0.09,0.11,0.14c0.04,0.06,0.07,0.12,0.09,0.19c0.03,0.06,0.04,0.14,0.05,0.2
          c0.01,0.03,0.02,0.07,0.01,0.1c0,0.01,0,0.02,0.01,0.03c0.01,0.03,0.02,0.06,0.03,0.09c0.02,0.05,0.04,0.09,0.05,0.14
          c0.01,0.08,0.06,0.16,0.05,0.24c0,0.01,0,0.01,0.01,0.02c0.02,0.1,0.05,0.2,0.07,0.29c0,0.01,0,0.01,0.01,0.02
          c0.03,0.04,0.02,0.08,0.02,0.12c0,0.07,0.01,0.14,0.03,0.2c0.02,0.06,0.02,0.11,0,0.17c-0.02,0.03-0.01,0.07,0,0.1
          c0.01,0.03,0.03,0.06,0.05,0.1c0.01,0.02,0.02,0.04,0.02,0.06c0.01,0.05,0.03,0.1,0.07,0.15c0.03,0.04,0.07,0.07,0.1,0.11
          c0.03,0.05,0.08,0.08,0.13,0.1c0.02,0.01,0.04,0.02,0.06,0.04c0.03,0.02,0.06,0.04,0.09,0.06c0.04,0.02,0.09,0.02,0.14,0.03
          c0.04,0,0.09,0.01,0.13,0c0.05-0.01,0.1,0,0.15,0c0.03,0,0.06,0,0.08,0c0.06,0,0.13-0.01,0.19,0c0.05,0.01,0.1,0.01,0.15-0.01
          c0.01,0,0.01,0,0.02,0c0.05,0,0.11,0,0.16-0.01c0.08,0,0.15-0.01,0.23-0.02c0.03,0,0.06,0,0.09,0.01c0.03,0.01,0.05,0.01,0.08,0.01
          c0.05-0.01,0.09,0,0.14,0c0.04,0,0.09,0,0.13-0.01c0.06-0.01,0.11-0.01,0.17,0c0.06,0,0.11,0,0.17,0.01c0.01,0,0.02,0,0.03,0
          c0.06,0,0.13-0.01,0.19-0.01c0.02,0,0.03,0,0.05,0.01c0.06,0.02,0.13,0.02,0.19,0.01c0.02,0,0.04-0.01,0.06-0.01
          c0.09-0.01,0.17,0.01,0.25-0.04c0,0,0,0,0.01,0c0.05-0.01,0.09-0.01,0.14-0.02c0.04,0,0.08,0,0.12-0.01
          c0.08-0.02,0.16-0.05,0.24-0.06c0.03-0.01,0.06-0.02,0.1-0.03c0.02-0.01,0.04-0.01,0.06-0.01c0.04-0.01,0.06-0.03,0.08-0.07
          c0.01-0.03,0.02-0.06,0.04-0.09c0.02-0.04,0.02-0.08,0-0.11C60.47,13.96,60.46,13.89,60.39,13.86z M55.98,13.88
          c-0.02-0.07-0.04-0.14-0.07-0.21c0-0.01-0.01-0.02-0.01-0.03c0.01-0.03,0-0.06-0.01-0.1c-0.01-0.05-0.02-0.09-0.04-0.13
          c-0.04-0.08-0.07-0.17-0.09-0.25c-0.02-0.08-0.04-0.16-0.07-0.24c-0.02-0.06-0.04-0.12-0.04-0.18c0-0.02,0-0.03-0.01-0.05
          c-0.03-0.07-0.06-0.14-0.08-0.21c-0.02-0.07-0.06-0.13-0.06-0.21c0-0.01-0.01-0.03-0.01-0.04c-0.01-0.05-0.03-0.09-0.04-0.14
          c0-0.03-0.01-0.05-0.03-0.08c-0.01-0.01-0.02-0.02-0.02-0.04c-0.01-0.04-0.04-0.07-0.06-0.11c-0.02-0.04-0.05-0.08-0.09-0.09
          c-0.01,0-0.01-0.01-0.02-0.02c-0.02-0.02-0.04-0.04-0.06-0.06c-0.08-0.09-0.18-0.12-0.29-0.12c-0.04,0-0.09,0-0.13,0.01
          c-0.05,0.01-0.1,0-0.15-0.01c-0.02-0.01-0.05-0.02-0.07-0.02c-0.09,0-0.18-0.03-0.27-0.05c-0.07-0.01-0.13-0.04-0.19-0.07
          c-0.02-0.01-0.04-0.02-0.06-0.04c-0.03-0.03-0.06-0.05-0.1-0.07c-0.05-0.02-0.09-0.05-0.14-0.09c-0.04-0.04-0.09-0.07-0.13-0.1
          c-0.02-0.02-0.05-0.04-0.06-0.07c-0.01-0.05-0.05-0.09-0.08-0.13c-0.04-0.05-0.08-0.09-0.11-0.15c-0.03-0.05-0.06-0.09-0.09-0.14
          c-0.01-0.02-0.02-0.03-0.04-0.04c-0.03-0.04-0.07-0.07-0.1-0.11c-0.03-0.04-0.06-0.09-0.1-0.14c-0.01-0.02-0.02-0.03-0.02-0.05
          c-0.01-0.04-0.02-0.08-0.04-0.11c-0.02-0.03-0.03-0.07-0.05-0.11c0-0.01-0.01-0.02-0.02-0.02c-0.02-0.02-0.02-0.04-0.03-0.06
          c0-0.03-0.02-0.06-0.04-0.09c-0.02-0.04-0.03-0.08-0.06-0.12c0,0-0.01-0.01-0.01-0.02c0-0.05-0.02-0.1-0.04-0.15
          c-0.02-0.05-0.03-0.11-0.03-0.17c0-0.03,0-0.07-0.01-0.1c-0.03-0.07-0.03-0.14-0.03-0.21c0.01-0.06,0.01-0.12-0.01-0.18
          c-0.01-0.06,0-0.11,0.01-0.16c0.01-0.03,0.01-0.05,0.01-0.08c0-0.03,0-0.06,0.01-0.08c0.01-0.06,0.04-0.11,0.05-0.18
          c0.01-0.03,0.02-0.07,0.05-0.09c0.03-0.04,0.05-0.08,0.07-0.12c0.01-0.02,0.02-0.04,0.03-0.06c0.01-0.02,0.02-0.04,0.03-0.06
          c0.01-0.02,0.01-0.04,0.02-0.05c0.03-0.06,0.06-0.12,0.1-0.17c0.04-0.06,0.06-0.13,0.08-0.2c0-0.02,0-0.04-0.01-0.06
          c-0.03-0.04-0.05-0.08-0.08-0.13c-0.02-0.04-0.04-0.07-0.06-0.11C53,7.38,52.98,7.35,52.97,7.31c-0.03-0.08-0.08-0.15-0.08-0.24
          c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.07-0.02-0.11c-0.01-0.02-0.02-0.05-0.03-0.08c0-0.02-0.01-0.03-0.01-0.05
          c-0.01-0.02-0.01-0.04-0.01-0.06c0.02-0.08-0.01-0.15-0.03-0.23c-0.01-0.02-0.01-0.05-0.02-0.07c-0.01-0.06,0-0.12-0.04-0.17
          c-0.01-0.01-0.01-0.02-0.01-0.03c0-0.08-0.02-0.16-0.04-0.24c-0.01-0.03-0.01-0.06-0.02-0.08c-0.02-0.04-0.03-0.09-0.03-0.14
          c0-0.06-0.02-0.12-0.05-0.17c-0.01-0.02-0.02-0.05-0.03-0.07c-0.01-0.05-0.01-0.1-0.04-0.14c-0.01-0.01-0.01-0.02-0.01-0.03
          c0-0.03-0.01-0.05-0.03-0.08c-0.01-0.02-0.02-0.04-0.03-0.06c-0.03-0.05-0.05-0.11-0.08-0.16c-0.05-0.09-0.11-0.17-0.15-0.26
          c0-0.01-0.01-0.02-0.01-0.03c-0.06-0.06-0.1-0.13-0.17-0.18C52,4.48,51.98,4.45,51.95,4.42c-0.03-0.03-0.05-0.07-0.08-0.1
          c-0.02-0.02-0.04-0.04-0.06-0.05c-0.03-0.01-0.05-0.03-0.07-0.05c-0.02-0.02-0.04-0.03-0.06-0.05c-0.01-0.01-0.03-0.02-0.04-0.04
          c-0.01-0.01-0.02-0.02-0.03-0.03c-0.07-0.04-0.11-0.1-0.16-0.15c-0.03-0.04-0.07-0.07-0.11-0.1c-0.01,0-0.02-0.01-0.02-0.02
          c-0.01-0.03-0.04-0.04-0.06-0.06c-0.02-0.02-0.04-0.04-0.06-0.06c-0.1-0.14-0.21-0.27-0.31-0.41c-0.03-0.05-0.06-0.11-0.09-0.16
          c-0.01-0.03-0.03-0.05-0.05-0.07c-0.03-0.03-0.05-0.07-0.06-0.12c0-0.03-0.02-0.05-0.04-0.08c-0.03-0.05-0.06-0.11-0.09-0.16
          c-0.03-0.05-0.04-0.11-0.06-0.16c-0.03-0.08-0.05-0.17-0.08-0.25c0-0.01-0.01-0.02-0.02-0.03c-0.06-0.07-0.08-0.16-0.12-0.24
          c0-0.01,0-0.02-0.01-0.03c-0.01-0.03-0.02-0.05-0.02-0.08c-0.02-0.05-0.04-0.11-0.05-0.16c-0.01-0.08-0.03-0.15-0.07-0.22
          c-0.01-0.01-0.02-0.03-0.02-0.05c0-0.05-0.01-0.1-0.02-0.15c-0.01-0.11-0.04-0.21-0.07-0.31c-0.01-0.03-0.01-0.06-0.01-0.09
          c0.01-0.05,0.02-0.1-0.01-0.15c-0.01-0.02,0-0.04-0.01-0.06c0-0.03-0.01-0.06-0.01-0.08c-0.02-0.05-0.02-0.11-0.04-0.16
          c-0.02-0.05-0.03-0.09-0.02-0.14c0-0.02,0-0.03-0.01-0.05c-0.01-0.05-0.02-0.09-0.02-0.14c0-0.01,0-0.01,0-0.02
          c-0.02-0.04-0.06-0.08-0.1-0.11C49.73,0.01,49.65-0.01,49.58,0c-0.09,0.02-0.18,0.04-0.27,0.06c-0.1,0.03-0.2,0.07-0.29,0.11
          c-0.06,0.02-0.12,0.04-0.16,0.09c-0.01,0.01-0.03,0.02-0.05,0.03c-0.04,0.03-0.09,0.06-0.13,0.09c-0.03,0.02-0.04,0.05-0.05,0.09
          c-0.01,0.05-0.01,0.1-0.01,0.15c0,0.04,0.02,0.09,0.03,0.13c0,0.01,0.01,0.01,0.01,0.02c0.02,0.02,0.04,0.05,0.07,0.06
          c0.02,0.01,0.04,0.03,0.05,0.05c0.02,0.03,0.04,0.06,0.07,0.08c0.02,0.03,0.04,0.06,0.05,0.09c0.01,0.03,0.01,0.05,0.03,0.07
          c0.04,0.08,0.1,0.15,0.14,0.23c0.01,0.01,0.02,0.03,0.03,0.04c0.02,0.04,0.05,0.07,0.06,0.11c0,0.01,0.01,0.03,0.02,0.04
          c0.03,0.03,0.04,0.07,0.05,0.12c0.02,0.07,0.05,0.13,0.09,0.19c0.02,0.02,0.03,0.04,0.03,0.07c0.02,0.06,0.03,0.11,0.04,0.17
          c0.01,0.04,0.03,0.07,0.04,0.1c0.01,0.02,0.02,0.03,0.02,0.05c0.01,0.02,0.01,0.04,0.02,0.07c0,0.01,0.02,0.03,0.02,0.04
          c0,0.06,0,0.12,0.02,0.18c-0.02,0.05,0,0.09,0.02,0.13c0.02,0.04,0.03,0.09,0.05,0.13c0.01,0.03,0.01,0.05,0,0.08
          c0,0.01,0,0.02,0,0.03c0.02,0.03,0.01,0.06,0.01,0.1c0.01,0.04,0.01,0.09,0.03,0.13c0,0.01,0.01,0.01,0.01,0.02
          c0.02,0.09,0.04,0.18,0.06,0.27c0,0.01,0,0.02,0,0.04c-0.01,0.06-0.01,0.12,0.01,0.17c0.01,0.02,0.01,0.04,0.01,0.06
          c0,0.05,0,0.1,0.02,0.15c0,0.01,0,0.02,0,0.03c-0.01,0.06-0.01,0.11,0,0.17c0,0.04,0.02,0.07,0.02,0.1c0,0.03,0,0.07,0,0.1
          c0,0.02,0,0.04-0.01,0.06c-0.02,0.05-0.01,0.1-0.01,0.15c0,0.03,0,0.05,0,0.08c-0.01,0.05-0.02,0.1-0.02,0.15
          c0,0.06,0.01,0.13,0.01,0.19c0,0.02,0,0.03,0,0.05c-0.01,0.06-0.01,0.12,0,0.18c0.01,0.04,0.01,0.08,0,0.12
          c-0.01,0.01-0.01,0.03,0,0.05c0.02,0.05,0.02,0.1,0.01,0.15c0,0.01,0,0.03,0,0.04c0.01,0.04,0.01,0.07-0.01,0.11
          c-0.01,0.03-0.01,0.06-0.01,0.09c0.01,0.04,0.01,0.08,0.01,0.12c-0.01,0.1-0.02,0.2-0.03,0.29c0,0.03-0.01,0.06,0,0.09
          c0.02,0.06,0.02,0.13,0.04,0.19c0,0.01,0.01,0.02,0.01,0.03c-0.01,0.08-0.01,0.17-0.02,0.25c-0.01,0.05-0.01,0.09,0,0.14
          c0.02,0.1,0.02,0.2,0.02,0.29c0,0.04-0.02,0.09,0,0.13c0.01,0.02,0,0.04,0,0.05c-0.01,0.03-0.02,0.07,0,0.1
          c0.02,0.03,0.02,0.06,0.02,0.09c0,0.03,0,0.06,0.01,0.08c0.01,0.03,0.01,0.05,0,0.08c-0.01,0.04,0,0.08,0.02,0.12
          c0.01,0.03,0.02,0.05,0.02,0.08c0.01,0.05,0.01,0.11,0.02,0.16c0.01,0.04,0.01,0.09,0.01,0.13c-0.01,0.05,0,0.09,0.03,0.13
          c0.01,0.02,0.06,0.15,0.06,0.17c0.01,0.09,0.04,0.18,0.06,0.27c0.01,0.04,0.03,0.08,0.03,0.12c-0.01,0.05,0.01,0.1,0.04,0.14
          c0.02,0.04,0.05,0.08,0.08,0.12c0.04,0.06,0.06,0.13,0.09,0.19c0.05,0.1,0.09,0.2,0.16,0.29c0,0.01,0.01,0.01,0.01,0.02
          c0,0.04,0.04,0.06,0.05,0.1c0.02,0.04,0.05,0.06,0.07,0.1c0.01,0.02,0.03,0.04,0.04,0.05c0.04,0.03,0.06,0.08,0.09,0.12
          c0.02,0.04,0.04,0.07,0.07,0.1c0.03,0.03,0.06,0.07,0.09,0.1c0.02,0.03,0.03,0.06,0.05,0.09c0.05,0.07,0.08,0.14,0.14,0.2
          c0.01,0.01,0.02,0.03,0.04,0.04c0.01,0.02,0.03,0.04,0.04,0.05c0.04,0.04,0.09,0.08,0.12,0.13c0,0.01,0.01,0.01,0.02,0.01
          c0.04,0.02,0.07,0.05,0.08,0.09c0,0.01,0.02,0.02,0.03,0.03c0.03,0.03,0.07,0.06,0.1,0.09c0.04,0.05,0.08,0.11,0.13,0.16
          c0.06,0.06,0.11,0.12,0.17,0.16c0.01,0.01,0.02,0.02,0.02,0.03c0.01,0.02,0.03,0.03,0.04,0.05c0.04,0.05,0.08,0.1,0.13,0.14
          c0.04,0.04,0.06,0.08,0.11,0.11c0.01,0.01,0.02,0.02,0.03,0.03c0.04,0.04,0.09,0.09,0.13,0.13c0.01,0.01,0.02,0.03,0.03,0.04
          c0.05,0.08,0.1,0.17,0.18,0.24c0,0,0.01,0.01,0.01,0.01c0.05,0.06,0.1,0.12,0.15,0.19c0.02,0.03,0.04,0.07,0.08,0.09
          c0.01,0,0.01,0.01,0.01,0.02c0.02,0.04,0.05,0.07,0.07,0.11c0.01,0.02,0.03,0.05,0.04,0.07c0.01,0.02,0.02,0.03,0.03,0.04
          c0.01,0.01,0.02,0.02,0.03,0.03c0.05,0.07,0.11,0.14,0.14,0.22c0.02,0.04,0.03,0.07,0.06,0.1c0.02,0.02,0.03,0.05,0.04,0.08
          c0.01,0.03,0.02,0.05,0.04,0.07c0.05,0.06,0.09,0.13,0.11,0.2c-0.01,0.07,0.05,0.12,0.05,0.18c0.03,0.04,0.03,0.08,0.06,0.12
          c0.04,0.06,0.06,0.13,0.08,0.2c0.01,0.02,0.01,0.04,0.03,0.05c0.06,0.05,0.09,0.11,0.11,0.17c0,0,0,0.01,0.01,0.01
          c0.03,0.05,0.05,0.1,0.05,0.16c0,0.01,0,0.02,0.01,0.03c0.03,0.08,0.09,0.14,0.16,0.19c0.01,0.01,0.02,0.01,0.03,0.01
          c0.08,0.02,0.16,0.04,0.24,0.03c0.04,0,0.08,0,0.11,0.01c0.03,0.01,0.06,0.01,0.1,0.01c0.07-0.01,0.14-0.01,0.22,0
          c0.05,0.01,0.1,0.02,0.15,0.03c0.08-0.01,0.15,0.01,0.23-0.01c0.02-0.01,0.05,0,0.08,0c0.07,0.01,0.14,0.03,0.21,0.03
          c0.09,0,0.17,0.01,0.26,0.01c0.01,0,0.03,0,0.04,0c0.08-0.01,0.16-0.01,0.25-0.02c0,0,0,0,0,0c0.03,0,0.06,0,0.09,0
          c0.09-0.01,0.16-0.06,0.23-0.11c0.01-0.01,0.03-0.03,0.03-0.04c0.02-0.04,0.03-0.08,0.04-0.12c0.01-0.06,0.01-0.11-0.02-0.16
          C56,13.95,55.99,13.91,55.98,13.88z M53.24,9.06c0.01,0.06,0.04,0.12,0.04,0.18c0,0.03,0,0.07,0.01,0.1
          c0.02,0.07,0.02,0.15,0.06,0.22c0.01,0.01,0.01,0.03,0.01,0.05c0,0.05,0.03,0.1,0.05,0.14c0.01,0.02,0.02,0.05,0.03,0.08
          c0,0.01,0.01,0.02,0.01,0.03c0.04,0.07,0.07,0.14,0.1,0.21c0.02,0.03,0.03,0.07,0.04,0.1c0.01,0.01,0.01,0.03,0.02,0.04
          c0.02,0.04,0.04,0.08,0.06,0.11c0.01,0.02,0.02,0.04,0.03,0.05c0.04,0.04,0.08,0.09,0.12,0.13c0.01,0.01,0.02,0.02,0.03,0.04
          c0.02,0.04,0.05,0.07,0.09,0.09c0.04,0.02,0.07,0.06,0.11,0.09c0.05,0.04,0.1,0.09,0.16,0.12c0.05,0.03,0.11,0.05,0.17,0.07
          c0.09,0.04,0.19,0.07,0.29,0.09c0.01,0,0.03,0,0.04,0.01c0.02,0.01,0.04,0.01,0.07,0.01c0.1-0.01,0.2-0.04,0.26-0.08
          c0.01-0.01,0.02-0.02,0.04-0.02c0.03-0.01,0.05-0.02,0.06-0.05c0.02-0.03,0.04-0.06,0.06-0.08c0.02-0.01,0.03-0.04,0.04-0.06
          c0.02-0.04,0.04-0.08,0.06-0.13c0.01-0.02,0.02-0.05,0.02-0.07c0.01-0.03-0.01-0.05-0.01-0.08c0-0.03,0.01-0.05,0.01-0.08
          c0-0.02-0.01-0.04-0.02-0.05c-0.01-0.03-0.03-0.05-0.02-0.09c0-0.09-0.03-0.17-0.05-0.26c0-0.02-0.01-0.03-0.02-0.04
          c-0.03-0.03-0.04-0.07-0.04-0.11c0-0.02-0.01-0.05-0.02-0.07c-0.01-0.02-0.02-0.05-0.03-0.08c-0.01-0.04-0.02-0.07-0.03-0.11
          c-0.06-0.19-0.14-0.37-0.24-0.55c-0.09-0.17-0.19-0.32-0.32-0.46c-0.05-0.06-0.11-0.11-0.16-0.16c-0.11-0.1-0.24-0.16-0.38-0.21
          c-0.03-0.01-0.05-0.02-0.08-0.02c-0.05-0.01-0.09-0.01-0.14-0.02c-0.03-0.01-0.07,0-0.1,0.01c-0.04,0.02-0.09,0.04-0.13,0.06
          c-0.04,0.02-0.07,0.04-0.1,0.06c-0.03,0.02-0.04,0.04-0.05,0.07c-0.02,0.06-0.04,0.12-0.06,0.18c-0.02,0.06-0.05,0.13-0.06,0.19
          c-0.01,0.08-0.04,0.15-0.03,0.24c0,0.01,0,0.02,0,0.03C53.23,9.01,53.23,9.03,53.24,9.06z"
        />
      </g>
    </svg>
  );
}
